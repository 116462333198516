import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { SecurityPositionsTypescript } from '../../../typescript'
import * as SecurityPositionsActions from '../../actions/securityPositions'
import * as types from '../../types'

const oneSecurityPositionRequisicao = () =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, 2000)
  })

let allSecurityPositionsApi: SecurityPositionsTypescript[]
const allSecurityPositionsRequisicao = async () => {
  await api({
    method: 'post',
    data: {
      query: `
        query {
          lastSecurityPosition {
            deviceId
            timeStamp
            activeButton
            statusIO
            latitude
            longitude
          }
        }
        `,
    },
  }).then(({ data }) => {
    allSecurityPositionsApi = data.data.lastSecurityPosition
    return true
  })
}

// let addDevicesApi: DevicesTypescript
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addSecurityPositionRequisicao = async (action: any) => {
  const securityPosition: SecurityPositionsTypescript = action.payload
  await api({
    method: 'post',
    data: {
      query: `
        mutation addSecurityPosition {
          createSecurityPositions(
            deviceId: "${securityPosition.deviceId}"
            timeStamp: "${securityPosition.timeStamp}"
            activeButton: "${securityPosition.activeButton}"
            statusIO: "${securityPosition.statusIO}"
            latitude: "${securityPosition.latitude}"
            longitude: "${securityPosition.longitude}"
            )
            {
            _id
            deviceId
            timeStamp
            activeButton
            statusIO
            latitude
            longitude
          }
        }
      `,
    },
  }).then(() => {
    // addDevicesApi = data.data.createDevices
    return true
  })
}

function* oneSecurityPosition() {
  try {
    yield call(oneSecurityPositionRequisicao)
    yield put(SecurityPositionsActions.oneSecurityPositionSuccess())
  } catch {
    yield put(SecurityPositionsActions.oneSecurityPositionFailure())
  }
}
function* allSecurityPositions() {
  try {
    yield call(allSecurityPositionsRequisicao)
    yield put(
      SecurityPositionsActions.allSecurityPositionsSuccess(
        allSecurityPositionsApi,
      ),
    )
  } catch {
    yield put(SecurityPositionsActions.allSecurityPositionsFailure())
  }
}
export default all([
  takeLatest(types.ONE_SECURITY_POSITION_REQUEST, oneSecurityPosition),
  takeLatest(types.ALL_SECURITY_POSITIONS_REQUEST, allSecurityPositions),
  takeLatest(
    types.ADD_SECURITY_POSITION_REQUEST,
    addSecurityPositionRequisicao,
  ),
])
