import { createSelector } from 'reselect'
import { ResellersTypescript } from '../../../typescript'

// Getters
const getResellers = (state: {
  resellers: { resellers: ResellersTypescript[] }
}) => state.resellers.resellers
const getAllowedResellers = (state: {
  resellers: { allowedResellers: ResellersTypescript[] }
}) => state.resellers.allowedResellers
const addReseller = (state: {
  resellers: { addReseller: ResellersTypescript }
}) => state.resellers.addReseller
const updReseller = (state: {
  resellers: { updReseller: ResellersTypescript }
}) => state.resellers.updReseller
const getReseller = (state: { resellers: { reseller: ResellersTypescript } }) =>
  state.resellers.reseller

// Loadings
const getLoading = (state: { resellers: { loading: boolean } }) =>
  state.resellers.loading
const allowedLoading = (state: { resellers: { allowedLoading: boolean } }) =>
  state.resellers.allowedLoading
const addLoading = (state: { resellers: { loadingAdd: boolean } }) =>
  state.resellers.loadingAdd
const updLoading = (state: { resellers: { loadingUpd: boolean } }) =>
  state.resellers.loadingUpd
const getOneLoading = (state: { resellers: { loadingOne: boolean } }) =>
  state.resellers.loadingOne

// exports
export const getResellersSelector = createSelector(
  getResellers,
  getLoading,
  (resellers: ResellersTypescript[]) => resellers,
)
export const getAllowedResellersSelector = createSelector(
  getAllowedResellers,
  allowedLoading,
  (resellers: ResellersTypescript[]) => resellers,
)
export const getResellerSelector = createSelector(
  getReseller,
  getOneLoading,
  (reseller: ResellersTypescript) => reseller,
)
export const addResellerSelector = createSelector(
  addReseller,
  addLoading,
  (addReseller: ResellersTypescript) => addReseller,
)
export const updResellerSelector = createSelector(
  updReseller,
  updLoading,
  (updReseller: ResellersTypescript) => updReseller,
)

// Exports Loadings
export const getLoadingResellersSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getAllowedLoadingResellersSelector = createSelector(
  allowedLoading,
  (allowedLoading: boolean) => allowedLoading,
)
export const getLoadingResellerSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddResellerSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdResellerSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
