import { createSelector } from 'reselect'
import { SystemLogsTypescript } from '../../../../typescript/logs'

// Getters
const getSystemLogs = (state: {
  systemLogs: { data: SystemLogsTypescript[] }
}) => state.systemLogs.data

// Loadings
const getLoading = (state: { systemLogs: { loading: boolean } }) =>
  state.systemLogs.loading

// exports
export const getSystemLogsSelector = createSelector(
  getSystemLogs,
  getLoading,
  (data: SystemLogsTypescript[]) => data,
)
// Exports Loadings
export const getLoadingSystemLogsSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
