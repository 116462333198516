import React, { FunctionComponent, LazyExoticComponent } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { decodeToken } from './utils/commons'

import './assets/scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout: LazyExoticComponent<FunctionComponent<any>> = React.lazy(
  () => import('./components/theme/theLayout'),
)

// Login
const Login: LazyExoticComponent<FunctionComponent<any>> = React.lazy(
  () => import('./views/login/index'),
)
const RecoveryPassword: LazyExoticComponent<FunctionComponent<any>> =
  React.lazy(() => import('./views/login/recoveryPassword'))
const ChangePassword: LazyExoticComponent<FunctionComponent<any>> = React.lazy(
  () => import('./views/login/changePassword'),
)

export default function App() {
  const PrivateRoute = ({ ...rest }): JSX.Element => {
    const token = decodeToken()
    if (token) {
      return token.exp * 1000 < new Date().getTime() ? (
        <Redirect to="/login" />
      ) : (
        <Route {...rest} />
      )
    }
    return <Redirect to="/login" />
  }
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/recoveryPassword"
            render={(props) => <RecoveryPassword {...props} />}
          />
          <Route
            exact
            path="/changePassword"
            render={(props) => <ChangePassword {...props} />}
          />
          {/* <PrivateRoute exact path="/register" render={(props) => <Register {...props}/>} /> */}
          <PrivateRoute path="/" render={(props) => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}
