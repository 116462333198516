/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CommandsTypescript } from '../../../typescript'
import { ParamsToFilter } from '../../../views/commands/dataTable/fields'
import * as types from '../../types'

export interface CommandState {
  loading: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  params: ParamsToFilter
  command: CommandsTypescript
  addCommand: CommandsTypescript
  updCommand: CommandsTypescript
  commands: CommandsTypescript[]
  error: string | null
}

const initialState: CommandState = {
  loading: true,
  loadingAdd: true,
  loadingUpd: true,
  params: {
    deviceId: '',
    registrationDate: '',
    commandType: '',
    status: undefined,
  },
  addCommand: {
    _id: '',
    deviceId: '',
    registrationUser: '',
    registrationDate: '',
    executedDate: '',
    canceledDate: '',
    commandType: '',
    params: '',
    executions: 0,
    status: 0,
    result: true,
    message: '',
    err: '',
  },
  updCommand: {
    _id: '',
    deviceId: '',
    registrationUser: '',
    registrationDate: '',
    executedDate: '',
    canceledDate: '',
    commandType: '',
    params: '',
    executions: 0,
    status: 0,
    result: true,
    message: '',
    err: '',
  },
  command: {
    _id: '',
    deviceId: '',
    registrationUser: '',
    registrationDate: '',
    executedDate: '',
    canceledDate: '',
    commandType: '',
    params: '',
    executions: 0,
    status: 0,
    result: true,
    message: '',
    err: '',
  },
  commands: [],
  error: null,
}

const commandReducer = (
  state = initialState,
  action: {
    type: string
    payload: CommandsTypescript[] | CommandsTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_COMMANDS_REQUEST:
      return {
        ...state,
        params: action.payload,
        loading: true,
        addCommand: { ...action.payload, result: false, message: '' },
        updCommand: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_COMMANDS_SUCCESS:
      return { ...state, commands: action.payload, loading: false }

    case types.ALL_COMMANDS_FAILURE:
      return state

    case types.ADD_COMMAND_REQUEST:
      return { ...state, addCommand: action.payload, loadingAdd: true }

    case types.ADD_COMMAND_SUCCESS:
      return { ...state, addCommand: action.payload, loadingAdd: false }

    case types.ADD_COMMAND_FAILURE:
      return { ...state, loadingAdd: false }

    case types.UPD_COMMAND_REQUEST:
      return { ...state, updCommand: action.payload, loadingUpd: true }

    case types.UPD_COMMAND_SUCCESS:
      return { ...state, updCommand: action.payload, loadingUpd: false }

    case types.UPD_COMMAND_FAILURE:
      return { ...state, updCommand: action.payload }
    default:
      return { ...state }
  }
}
export default commandReducer
