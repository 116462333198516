import { SecurityPositionsTypescript } from '../../../typescript'
import * as types from '../../types'

export interface SecurityPositionState {
  loading: boolean
  securityPosition: SecurityPositionsTypescript
  securityPositions: SecurityPositionsTypescript[]
  error: string | null
}

const initialState: SecurityPositionState = {
  loading: true,
  securityPosition: {
    _id: '',
    deviceId: '',
    timeStamp: '',
    activeButton: '',
    statusIO: '',
    latitude: 0,
    longitude: 0,
  },
  securityPositions: [],
  error: null,
}

const securityPositionReducer = (
  state = initialState,
  action: {
    type: string
    payload: SecurityPositionsTypescript[] | SecurityPositionsTypescript
  },
) => {
  switch (action.type) {
    case types.ONE_SECURITY_POSITION_REQUEST:
      return { ...state, loading: true }

    case types.ONE_SECURITY_POSITION_SUCCESS:
      return { ...state, data: action.payload }

    case types.ONE_SECURITY_POSITION_FAILURE:
      return state

    case types.ALL_SECURITY_POSITIONS_REQUEST:
      return { ...state, loading: true }

    case types.ALL_SECURITY_POSITIONS_SUCCESS:
      return { ...state, securityPositions: action.payload, loading: false }

    case types.ALL_SECURITY_POSITIONS_FAILURE:
      return state

    case types.ADD_SECURITY_POSITION_REQUEST:
      return { ...state, loading: true }

    case types.ADD_SECURITY_POSITION_SUCCESS:
      return { ...state, loading: true }

    case types.ADD_SECURITY_POSITION_FAILURE:
      return state

    case types.UPD_SECURITY_POSITION_REQUEST:
      return state

    case types.UPD_SECURITY_POSITION_SUCCESS:
      return { ...state }

    case types.UPD_SECURITY_POSITION_FAILURE:
      return state

    case types.DEL_SECURITY_POSITION_REQUEST:
      return state

    case types.DEL_SECURITY_POSITION_SUCCESS:
      return { ...state }

    case types.DEL_SECURITY_POSITION_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default securityPositionReducer
