/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DeviceTypesTypescript } from '../../../typescript'
import * as types from '../../types'

export interface DeviceTypeState {
  loading: boolean
  loadingToSearch: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  deviceType: DeviceTypesTypescript
  addDeviceType: DeviceTypesTypescript
  updDeviceType: DeviceTypesTypescript
  deviceTypes: DeviceTypesTypescript[]
  deviceTypesToSearch: DeviceTypesTypescript[]
  deviceTypesFiltered: DeviceTypesTypescript[]
  error: string | null
}

const initialState: DeviceTypeState = {
  loading: false,
  loadingToSearch: false,
  loadingOne: false,
  loadingAdd: false,
  loadingUpd: false,
  addDeviceType: {
    _id: '',
    description: '',
    type: '',
    result: false,
    message: '',
    err: '',
  },
  updDeviceType: {
    _id: '',
    description: '',
    type: '',
    result: false,
    message: '',
    err: '',
  },
  deviceType: {
    _id: '',
    description: '',
    type: '',
    result: false,
    message: '',
    err: '',
  },
  deviceTypes: [],
  deviceTypesToSearch: [],
  deviceTypesFiltered: [],
  error: null,
}

const deviceTypeReducer = (
  state = initialState,
  action: {
    type: string
    payload: DeviceTypesTypescript[] | DeviceTypesTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_DEVICE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        addDeviceType: { ...action.payload, result: false, message: '' },
        updDeviceType: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_DEVICE_TYPES_SUCCESS:
      return { ...state, deviceTypes: action.payload, loading: false }

    case types.ALL_DEVICE_TYPES_FAILURE:
      return { ...state, loading: false }

    case types.TYPES_DEVICE_TYPES_REQUEST:
      return {
        ...state,
        loadingToSearch: true,
      }

    case types.TYPES_DEVICE_TYPES_SUCCESS:
      return {
        ...state,
        deviceTypesToSearch: action.payload,
        loadingToSearch: false,
      }

    case types.TYPES_DEVICE_TYPES_FAILURE:
      return { ...state, loadingToSearch: false }

    case types.ADD_DEVICE_TYPE_REQUEST:
      return { ...state, addDeviceType: action.payload, loadingAdd: true }

    case types.ADD_DEVICE_TYPE_SUCCESS:
      return { ...state, addDeviceType: action.payload, loadingAdd: false }

    case types.ADD_DEVICE_TYPE_FAILURE:
      return state

    case types.UPD_DEVICE_TYPE_REQUEST:
      return { ...state, updDeviceType: action.payload, loadingUpd: true }

    case types.UPD_DEVICE_TYPE_SUCCESS:
      return { ...state, updDeviceType: action.payload, loadingUpd: false }

    case types.UPD_DEVICE_TYPE_FAILURE:
      return { ...state, updDeviceType: action.payload }

    case types.DEL_DEVICE_TYPE_REQUEST:
      return state

    case types.DEL_DEVICE_TYPE_SUCCESS:
      return { ...state }

    case types.DEL_DEVICE_TYPE_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default deviceTypeReducer
