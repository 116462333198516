/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  DashboardCardsDevicesTypescript,
  DashboardCardsGroupsTypescript,
  DashboardCardsTypescript,
} from '../../../typescript'
import * as types from '../../types'

export interface DashboardCardsState {
  loading: boolean
  loadingGroups: boolean
  loadingDevices: boolean
  loadingOnlineOffline: boolean
  loadingAlertsFail: boolean
  dashboardCards: DashboardCardsTypescript
  dashboardCardsGroups: DashboardCardsGroupsTypescript
  dashboardCardsDevices: DashboardCardsDevicesTypescript
  dashboardCardsOnlineOffline: DashboardCardsTypescript
  dashboardCardsAlertsFail: DashboardCardsTypescript
  error: string | null
}

const initialState: DashboardCardsState = {
  loading: true,
  loadingGroups: true,
  loadingDevices: true,
  loadingOnlineOffline: true,
  loadingAlertsFail: true,
  dashboardCards: {
    economigGroupsCount: 0,
    temperatureDevicesCount: 0,
    temperatureDevicesOnline: 0,
    temperatureDevicesOffline: 0,
    temperatureHighAlert: 0,
    temperatureLowAlert: 0,
    temperatureFailAlert: 0,
    temperatureInactive: 0,
  },
  dashboardCardsGroups: {
    groupName: '',
  },
  dashboardCardsDevices: {
    description: '',
  },

  dashboardCardsOnlineOffline: {
    serialNumber: '',
    description: '',
    branchNumber: 0,
    timeStamp: '',
    economicGroupDetail: {
      groupName: '',
      groupNumber: 0,
    },
  },
  dashboardCardsAlertsFail: {
    serialNumber: '',
    description: '',
    branchNumber: 0,
    timeStamp: '',
    economicGroupDetail: {
      groupName: '',
      groupNumber: 0,
    },
    temperatureOne: 0,
    temperatureTwo: 0,
    temperatureThree: 0,
    minimumAlertOne: 0,
    maximumAlertOne: 0,
    minimumAlertTwo: 0,
    maximumAlertTwo: 0,
    minimumAlertThree: 0,
    maximumAlertThree: 0,
  },
  error: null,
}

const dashboardCardsReducer = (
  state = initialState,
  action: {
    type: string
    payload: DashboardCardsTypescript
  },
) => {
  switch (action.type) {
    case types.DASHBOARD_CARDS_REQUEST:
      return { ...state, loading: true, data: action.payload }

    case types.DASHBOARD_CARDS_SUCCESS:
      return { ...state, dashboardCards: action.payload, loading: false }

    case types.DASHBOARD_CARDS_FAILURE:
      return state

    case types.DASHBOARD_CARDS_GROUPS_REQUEST:
      return { ...state, loadingGroups: true, data: action.payload }

    case types.DASHBOARD_CARDS_GROUPS_SUCCESS:
      return {
        ...state,
        dashboardCardsGroups: action.payload,
        loadingGroups: false,
      }

    case types.DASHBOARD_CARDS_GROUPS_FAILURE:
      return state

    case types.DASHBOARD_CARDS_DEVICES_REQUEST:
      return { ...state, loadingDevices: true, data: action.payload }

    case types.DASHBOARD_CARDS_DEVICES_SUCCESS:
      return {
        ...state,
        dashboardCardsDevices: action.payload,
        loadingDevices: false,
      }

    case types.DASHBOARD_CARDS_DEVICES_FAILURE:
      return state

    case types.DASHBOARD_CARDS_ONLINE_OFFLINE_REQUEST:
      return { ...state, data: action.payload, loadingOnlineOffline: true }

    case types.DASHBOARD_CARDS_ONLINE_OFFLINE_SUCCESS:
      return {
        ...state,
        dashboardCardsOnlineOffline: action.payload,
        loadingOnlineOffline: false,
      }

    case types.DASHBOARD_CARDS_ONLINE_OFFLINE_FAILURE:
      return state

    case types.DASHBOARD_CARDS_ALERTS_FAIL_REQUEST:
      return { ...state, alertsFail: action.payload, loadingAlertsFail: true }

    case types.DASHBOARD_CARDS_ALERTS_FAIL_SUCCESS:
      return {
        ...state,
        dashboardCardsAlertsFail: action.payload,
        loadingAlertsFail: false,
      }

    case types.DASHBOARD_CARDS_ALERTS_FAIL_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default dashboardCardsReducer
