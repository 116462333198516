import { ProfilesTypescript } from '../../../typescript'
import * as types from '../../types'

export interface ProfileState {
  loading: boolean
  loadingFiltered: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  profile: ProfilesTypescript
  addProfile: ProfilesTypescript
  updProfile: ProfilesTypescript
  profiles: ProfilesTypescript[]
  ProfilesFiltered: ProfilesTypescript[]
  error: string | null
}

const initialState: ProfileState = {
  loading: true,
  loadingFiltered: true,
  loadingOne: true,
  loadingAdd: true,
  loadingUpd: true,
  addProfile: {
    _id: '',
    profileName: '',
    usersRegistration: 0,
    economicGroupsRegistration: 0,
    branchesRegistration: 0,
    devicesRegistration: 0,
    commandsRegistration: 0,
    devicesTypeRegistration: 0,
    profilesRegistration: 0,
    result: false,
    message: '',
    err: '',
  },
  updProfile: {
    _id: '',
    profileName: '',
    usersRegistration: 0,
    economicGroupsRegistration: 0,
    branchesRegistration: 0,
    devicesRegistration: 0,
    commandsRegistration: 0,
    devicesTypeRegistration: 0,
    profilesRegistration: 0,
    result: false,
    message: '',
    err: '',
  },
  profile: {
    _id: '',
    profileName: '',
    usersRegistration: 0,
    economicGroupsRegistration: 0,
    branchesRegistration: 0,
    devicesRegistration: 0,
    commandsRegistration: 0,
    devicesTypeRegistration: 0,
    profilesRegistration: 0,
    result: false,
    message: '',
    err: '',
  },
  profiles: [],
  ProfilesFiltered: [],
  error: null,
}

const profileReducer = (
  state = initialState,
  action: {
    type: string
    payload: ProfilesTypescript[] | ProfilesTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_PROFILES_REQUEST:
      return {
        ...state,
        loading: true,
        profile: action.payload,
        addProfile: { ...action.payload, result: false, message: '' },
        updProfile: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_PROFILES_SUCCESS:
      return { ...state, profiles: action.payload, loading: false }

    case types.ALL_PROFILES_FAILURE:
      return state

    case types.ADD_PROFILE_REQUEST:
      return { ...state, addProfile: action.payload, loadingAdd: true }

    case types.ADD_PROFILE_SUCCESS:
      return { ...state, addProfile: action.payload, loadingAdd: false }

    case types.ADD_PROFILE_FAILURE:
      return state

    case types.UPD_PROFILE_REQUEST:
      return { ...state, updProfile: action.payload, loadingUpd: true }

    case types.UPD_PROFILE_SUCCESS:
      return { ...state, updProfile: action.payload, loadingUpd: false }

    case types.UPD_PROFILE_FAILURE:
      return { ...state, updProfile: action.payload }

    case types.DEL_PROFILE_REQUEST:
      return state

    case types.DEL_PROFILE_SUCCESS:
      return { ...state }

    case types.DEL_PROFILE_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default profileReducer
