import { DevicesTypescript } from '../../../typescript'
import * as types from '../../types'

export function allDevicesRequest(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.ALL_DEVICES_REQUEST,
    payload: device,
  }
}
export function allDevicesSuccess(data: DevicesTypescript[]): {
  type: string
  payload: DevicesTypescript[]
} {
  return {
    type: types.ALL_DEVICES_SUCCESS,
    payload: data,
  }
}
export function allDevicesFailure(): { type: string } {
  return {
    type: types.ALL_DEVICES_FAILURE,
  }
}
//one
export function oneDeviceRequest(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.ONE_DEVICE_REQUEST,
    payload: device,
  }
}
export function oneDeviceSuccess(data: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.ONE_DEVICE_SUCCESS,
    payload: data,
  }
}
export function oneDeviceFailure(): { type: string } {
  return {
    type: types.ONE_DEVICE_FAILURE,
  }
}

export function allDevicesByGroupRequest(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.ALL_DEVICES_BY_GROUP_REQUEST,
    payload: device,
  }
}
export function allDevicesByGroupSuccess(data: DevicesTypescript[]): {
  type: string
  payload: DevicesTypescript[]
} {
  return {
    type: types.ALL_DEVICES_BY_GROUP_SUCCESS,
    payload: data,
  }
}
export function allDevicesByGroupFailure(): { type: string } {
  return {
    type: types.ALL_DEVICES_BY_GROUP_FAILURE,
  }
}

export function dashBoardDevicesRequest(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.DASHBOARD_CARD_DEVICES_REQUEST,
    payload: device,
  }
}
export function dashBoardDevicesSuccess(data: DevicesTypescript[]): {
  type: string
  payload: DevicesTypescript[]
} {
  return {
    type: types.DASHBOARD_CARD_DEVICES_SUCCESS,
    payload: data,
  }
}
export function dashBoardDevicesFailure(): { type: string } {
  return {
    type: types.DASHBOARD_CARD_DEVICES_FAILURE,
  }
}

export function addDeviceRequest(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.ADD_DEVICE_REQUEST,
    payload: device,
  }
}
export function addDeviceSuccess(data: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.ADD_DEVICE_SUCCESS,
    payload: data,
  }
}
export function addDeviceFailure(): { type: string } {
  return {
    type: types.ADD_DEVICE_FAILURE,
  }
}

export function updDeviceRequest(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.UPD_DEVICE_REQUEST,
    payload: device,
  }
}
export function updDeviceSuccess(data: DevicesTypescript[]): {
  type: string
  payload: DevicesTypescript[]
} {
  return {
    type: types.UPD_DEVICE_SUCCESS,
    payload: data,
  }
}
export function updDeviceFailure(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.UPD_DEVICE_FAILURE,
    payload: device,
  }
}

export function updGroupDeviceRequest(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.UPD_GROUP_DEVICE_REQUEST,
    payload: device,
  }
}
export function updGroupDeviceSuccess(data: DevicesTypescript[]): {
  type: string
  payload: DevicesTypescript[]
} {
  return {
    type: types.UPD_GROUP_DEVICE_SUCCESS,
    payload: data,
  }
}
export function updGroupDeviceFailure(device: DevicesTypescript): {
  type: string
  payload: DevicesTypescript
} {
  return {
    type: types.UPD_GROUP_DEVICE_FAILURE,
    payload: device,
  }
}
