import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { EconomicGroupsTypescript } from '../../../typescript'
import * as EconomicGroupsActions from '../../actions/economicGroups'
import * as types from '../../types'

let oneEconomicGroupApi: EconomicGroupsTypescript
const oneEconomicGroupRequisicao = async (param: EconomicGroupsTypescript) => {
  const query = `
    query {
      oneEconomicGroup(_id: "${param._id}") {
        _id
        groupNumber
        groupName
        tradingName
        identificationDocument
        phoneNumber
        email
        responsible
        observations
        notificationsEmails
        active
        associatedResaleDetail{
            _id
            resaleName
            resaleNumber
        }
        result
        message
        err
      }
    }
    `

  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      oneEconomicGroupApi = data.data.oneEconomicGroup
      return true
    })
    .catch((e) => console.log('meu erro é: ', e))
}

let actualEconomicGroupApi: EconomicGroupsTypescript
const actualEconomicGroupRequisicao = async (
  param: EconomicGroupsTypescript,
) => {
  const query = `
    query {
      oneEconomicGroup(_id: "${param._id}") {
        _id
        groupNumber
        groupName
        tradingName
        identificationDocument
        phoneNumber
        email
        responsible
        observations
        notificationsEmails
        active
        associatedResaleDetail{
            _id
            resaleName
            resaleNumber
        }
        result
        message
        err
      }
    }
    `

  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      actualEconomicGroupApi = data.data.oneEconomicGroup
      return true
    })
    .catch((e) => console.log('meu erro é: ', e))
}

let allEconomicGroupsAuthorizedApi: EconomicGroupsTypescript[]
const allEconomicGroupsAuthorizedRequisicao = async (
  economicGroup: EconomicGroupsTypescript,
) => {
  const query = `
  query {
    allEconomicGroupsAuthorized (
      find: {
        ${
          economicGroup.identificationDocument
            ? 'identificationDocument: "' +
              economicGroup.identificationDocument +
              '"'
            : ''
        }
        ${
          economicGroup.groupName
            ? 'groupName: "' + economicGroup.groupName + '"'
            : ''
        }
        ${
          economicGroup.groupNumber
            ? 'groupNumber: ' + economicGroup.groupNumber
            : ''
        }
        ${
          economicGroup.tradingName
            ? 'tradingName: "' + economicGroup.tradingName + '"'
            : ''
        }
      }) {
      _id
      groupNumber
      groupName
      tradingName
      identificationDocument
      phoneNumber
      email
      responsible
      notificationsEmails
      observations
      observations
      active
      associatedResaleDetail{
          _id
          resaleName
          resaleNumber
      }
      result
      message
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allEconomicGroupsAuthorizedApi = data.data.allEconomicGroupsAuthorized
    return true
  })
}

let allEconomicGroupsApi: EconomicGroupsTypescript[]
const allEconomicGroupsRequisicao = async (
  economicGroup: EconomicGroupsTypescript,
) => {
  const query = `
  query {
    allEconomicGroups (
      find: {
        ${economicGroup._id ? '_id: "' + economicGroup._id + '"' : ''}
        ${
          economicGroup.identificationDocument
            ? 'identificationDocument: "' +
              economicGroup.identificationDocument +
              '"'
            : ''
        }
        ${
          economicGroup.groupName
            ? 'groupName: "' + economicGroup.groupName + '"'
            : ''
        }
        ${
          economicGroup.groupNumber
            ? 'groupNumber: ' + economicGroup.groupNumber
            : ''
        }
        ${
          economicGroup.tradingName
            ? 'tradingName: "' + economicGroup.tradingName + '"'
            : ''
        }
      }) {
      _id
      groupNumber
      groupName
      tradingName
      identificationDocument
      phoneNumber
      email
      responsible
      notificationsEmails
      observations
      observations
      active
      associatedResaleDetail{
          _id
          resaleName
          resaleNumber
      }
      result
      message
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allEconomicGroupsApi = data.data.allEconomicGroups
    return true
  })
}

// Abaixo retirei dos retornos valoresnão utilizados
/* 
  _id
  groupNumber
  groupName
  tradingName
  identificationDocument
  phoneNumber
  email
  responsible
  observations
  observations
  active
*/
let allEconomicGroupsToResellersApi: EconomicGroupsTypescript[]
const allEconomicGroupsToResellersRequisicao = async (
  economicGroup: EconomicGroupsTypescript,
) => {
  const query = `
  query {
    economicGroupsToResellers (find: {
      ${
        economicGroup.resaleNumber
          ? 'resaleNumber: ' + economicGroup.resaleNumber
          : ''
      }
    }) {
      groupNumber
      groupName
      tradingName
      result
      message
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allEconomicGroupsToResellersApi = data.data.economicGroupsToResellers
    return true
  })
}

let addEconomicGroupApi
const addEconomicGroupRequisicao = async (
  addEconomicGroup: EconomicGroupsTypescript,
) => {
  await api({
    method: 'post',
    data: {
      query: `
        mutation {
          createEconomicGroups(input:{
            groupName: "${addEconomicGroup.groupName}",
            tradingName: "${addEconomicGroup.tradingName}",
            identificationDocument: "${addEconomicGroup.identificationDocument}",
            idResale: "${addEconomicGroup.associatedResaleDetail?._id}",
            phoneNumber: "${addEconomicGroup.phoneNumber}",
            email: "${addEconomicGroup.email}",
            responsible: "${addEconomicGroup.responsible}",
            notificationsEmails: "${addEconomicGroup.notificationsEmails}",
            observations: "${addEconomicGroup.observations}",            
            active: ${addEconomicGroup.active}}) {           
            result
            message
            err
          }
        }
      `,
    },
  })
    .then(({ data }) => {
      addEconomicGroupApi = data.data.createEconomicGroups
      return true
    })
    .catch((e) => e)
}

let updateEconomicGroupApi
const updateEconomicGroupRequisicao = async (
  updEconomicGroup: EconomicGroupsTypescript,
) => {
  await api({
    method: 'post',
    data: {
      query: `
        mutation {
          updateEconomicGroups(update:{
            _id: "${updEconomicGroup._id}",
            groupName: "${updEconomicGroup.groupName}",
            tradingName: "${updEconomicGroup.tradingName}",
            identificationDocument: "${updEconomicGroup.identificationDocument}",
            idResale: "${updEconomicGroup.associatedResaleDetail?._id}",
            phoneNumber: "${updEconomicGroup.phoneNumber}",
            email: "${updEconomicGroup.email}",
            responsible: "${updEconomicGroup.responsible}",
            notificationsEmails: "${updEconomicGroup.notificationsEmails}",
            observations: "${updEconomicGroup.observations}",
            active: ${updEconomicGroup.active}}) {            
            result
            message
            err
          }
        }
      `,
    },
  })
    .then(({ data }) => {
      updateEconomicGroupApi = data.data.updateEconomicGroups
      return true
    })
    .catch((e) => e)
}

function* allEconomicGroupsAuthorized(action) {
  try {
    yield call(allEconomicGroupsAuthorizedRequisicao, action.payload)
    yield put(
      EconomicGroupsActions.allEconomicGroupsAuthorizedSuccess(
        allEconomicGroupsAuthorizedApi,
      ),
    )
  } catch {
    yield put(EconomicGroupsActions.allEconomicGroupsAuthorizedFailure())
  }
}

function* allEconomicGroups(action) {
  try {
    yield call(allEconomicGroupsRequisicao, action.payload)
    yield put(
      EconomicGroupsActions.allEconomicGroupsSuccess(allEconomicGroupsApi),
    )
  } catch {
    yield put(EconomicGroupsActions.allEconomicGroupsFailure())
  }
}
function* allEconomicGroupsToResellers(action) {
  try {
    yield call(allEconomicGroupsToResellersRequisicao, action.payload)
    yield put(
      EconomicGroupsActions.allEconomicGroupsToResellersSuccess(
        allEconomicGroupsToResellersApi,
      ),
    )
  } catch {
    yield put(EconomicGroupsActions.allEconomicGroupsToResellersFailure())
  }
}
function* oneEconomicGroup(action) {
  try {
    yield call(oneEconomicGroupRequisicao, action.payload)
    yield put(
      EconomicGroupsActions.oneEconomicGroupSuccess(oneEconomicGroupApi),
    )
  } catch {
    yield put(EconomicGroupsActions.oneEconomicGroupFailure())
  }
}
function* actualEconomicGroup(action) {
  try {
    yield call(actualEconomicGroupRequisicao, action.payload)
    yield put(
      EconomicGroupsActions.actualEconomicGroupSuccess(actualEconomicGroupApi),
    )
  } catch {
    yield put(EconomicGroupsActions.actualEconomicGroupFailure())
  }
}

function* addEconomicGroup(action) {
  try {
    yield call(addEconomicGroupRequisicao, action.payload)
    yield put(
      EconomicGroupsActions.addEconomicGroupSuccess(addEconomicGroupApi),
    )
  } catch {
    yield put(EconomicGroupsActions.addEconomicGroupFailure())
  }
}

function* updEconomicGroup(action) {
  try {
    yield call(updateEconomicGroupRequisicao, action.payload)
    yield put(
      EconomicGroupsActions.updEconomicGroupSuccess(updateEconomicGroupApi),
    )
  } catch {
    yield put(
      EconomicGroupsActions.updEconomicGroupFailure(updateEconomicGroupApi),
    )
  }
}

export default all([
  takeLatest(types.ALL_ECONOMIC_GROUPS_REQUEST, allEconomicGroups),
  takeLatest(
    types.ALL_ECONOMIC_GROUPS_TO_RESSELERS_REQUEST,
    allEconomicGroupsToResellers,
  ),
  takeLatest(
    types.ALL_ECONOMIC_GROUPS_AUTHORIZED_REQUEST,
    allEconomicGroupsAuthorized,
  ),
  takeLatest(types.ONE_ECONOMIC_GROUP_REQUEST, oneEconomicGroup),
  takeLatest(types.ACTUAL_ECONOMIC_GROUP_REQUEST, actualEconomicGroup),
  takeLatest(types.ADD_ECONOMIC_GROUP_REQUEST, addEconomicGroup),
  takeLatest(types.UPD_ECONOMIC_GROUP_REQUEST, updEconomicGroup),
])
