import { createSelector } from 'reselect'
import { CommandsTypescript } from '../../../typescript'

// Getters
const getCommands = (state: { commands: { commands: CommandsTypescript[] } }) =>
  state.commands.commands
const addCommand = (state: { commands: { addCommand: CommandsTypescript } }) =>
  state.commands.addCommand
const updCommand = (state: { commands: { updCommand: CommandsTypescript } }) =>
  state.commands.updCommand
const getCommand = (state: { commands: { command: CommandsTypescript } }) =>
  state.commands.command

// Loadings
const getLoading = (state: { commands: { loading: boolean } }) =>
  state.commands.loading
const addLoading = (state: { commands: { loadingAdd: boolean } }) =>
  state.commands.loadingAdd
const updLoading = (state: { commands: { loadingUpd: boolean } }) =>
  state.commands.loadingUpd
const getOneLoading = (state: { commands: { loadingOne: boolean } }) =>
  state.commands.loadingOne

// exports
export const getCommandsSelector = createSelector(
  getCommands,
  getLoading,
  (commands: CommandsTypescript[]) => commands,
)
export const getCommandSelector = createSelector(
  getCommand,
  getOneLoading,
  (command: CommandsTypescript) => command,
)
export const addCommandSelector = createSelector(
  addCommand,
  addLoading,
  (addCommand: CommandsTypescript) => addCommand,
)
export const updCommandSelector = createSelector(
  updCommand,
  updLoading,
  (updCommand: CommandsTypescript) => updCommand,
)

// Exports Loadings
export const getLoadingCommandsSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingCommandSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddCommandSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdCommandSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
