import { ReportTemperatureDetailsTypescript } from '../../../../typescript/reports/temperatureDetails'
import * as types from '../../../types'

export interface ReportTemperatureDetailsState {
  loading: boolean
  loadingByDate: boolean
  data: ReportTemperatureDetailsTypescript
  dataByDate: ReportTemperatureDetailsTypescript
  error: string | null
}

const initialState: ReportTemperatureDetailsState = {
  loading: false,
  loadingByDate: false,
  data: {
    result: false,
    message: '',
  },
  dataByDate: {
    result: false,
    message: '',
  },
  error: null,
}

export const reportTemperatureDetailsReducer = (
  state = initialState,
  action: {
    type: string
    payload: ReportTemperatureDetailsTypescript
  },
) => {
  switch (action.type) {
    // Detalhado
    case types.ALL_REPORT_TEMPERATURE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
      }

    case types.ALL_REPORT_TEMPERATURE_DETAILS_SUCCESS:
      return { ...state, data: action.payload, loading: false }

    case types.ALL_REPORT_TEMPERATURE_DETAILS_FAILURE:
      return { ...state, data: action.payload, loading: false }

    // Sintético
    case types.SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_REQUEST:
      return {
        ...state,
        loadingByDate: true,
        dataByDate: action.payload,
      }

    case types.SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_SUCCESS:
      return { ...state, dataByDate: action.payload, loadingByDate: false }

    case types.SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_FAILURE:
      return { ...state, dataByDate: action.payload, loadingByDate: false }

    default:
      return state
  }
}
