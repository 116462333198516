import { SectionState, SectionsTypescript } from '../../../typescript'
import * as types from '../../types'

const initialState: SectionState = {
  loading: false,
  loadingOne: false,
  loadingAdd: false,
  loadingUpd: false,
  addSection: {
    _id: '',
    idBranch: '',
    idEconomicGroup: '',
    sectionNumber: undefined,
    description: '',
    xCoordinate: undefined,
    yCoordinate: undefined,
    result: false,
    message: '',
    err: '',
  },
  updSection: {
    _id: '',
    idBranch: '',
    idEconomicGroup: '',
    sectionNumber: undefined,
    description: '',
    xCoordinate: undefined,
    yCoordinate: undefined,
    result: false,
    message: '',
    err: '',
  },
  section: {
    _id: '',
    idBranch: '',
    idEconomicGroup: '',
    sectionNumber: undefined,
    description: '',
    xCoordinate: undefined,
    yCoordinate: undefined,
    result: false,
    message: '',
    err: '',
  },
  sections: [],
  error: null,
}

const sectionReducer = (
  state = initialState,
  action: {
    type: string
    payload: SectionsTypescript[] | SectionsTypescript
  },
) => {
  switch (action.type) {
    case types.ONE_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
        section: action.payload,
        addSection: { ...action.payload, result: false, message: '' },
        updSection: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
        loadingUpdGroup: true,
      }
    case types.ONE_SECTION_SUCCESS:
      return {
        ...state,
        section: action.payload,
        loadingOne: false,
        loadingUpdGroup: false,
      }
    case types.ONE_SECTION_FAILURE:
      return state

    case types.ALL_SECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        addSection: { ...action.payload, result: false, message: '' },
        updSection: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }
    case types.ALL_SECTIONS_SUCCESS:
      return { ...state, sections: action.payload, loading: false }
    case types.ALL_SECTIONS_FAILURE:
      return { ...state, loading: false }

    case types.ADD_SECTION_REQUEST:
      return { ...state, addSection: action.payload, loadingAdd: true }

    case types.ADD_SECTION_SUCCESS:
      return { ...state, addSection: action.payload, loadingAdd: false }

    case types.ADD_SECTION_FAILURE:
      return { ...state, loadingAdd: false }

    case types.UPD_SECTION_REQUEST:
      return {
        ...state,
        updSection: action.payload,
        loadingUpd: true,
      }

    case types.UPD_SECTION_SUCCESS:
      return { ...state, updSection: action.payload, loadingUpd: false }

    case types.UPD_SECTION_FAILURE:
      return state

    case types.DEL_SECTION_REQUEST:
      return state

    case types.DEL_SECTION_SUCCESS:
      return { ...state }

    case types.DEL_SECTION_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default sectionReducer
