import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as Actions from '../../../actions/logs'
import * as types from '../../../types'
import api from '../../../../api'
import { FindSystemLogsTypescript } from '../../../../typescript/logs'

const allSystemLogsRequest = async (find: FindSystemLogsTypescript) => {
  // startTimeStamp: "2021-01-01"
  //         stopTimeStamp: "2022-01-26"
  //         requisition:"createUser"
  //         answer:"false"
  //         statusCode: 200
  //         remoteAddress:"45.160.104.40"

  return new Promise((resolve, reject) => {
    const query = `
    query{
      allSystemLogs(find:{      
        ${
          find.startTimeStamp
            ? 'startTimeStamp: "' + find.startTimeStamp + '"'
            : ''
        }
        ${
          find.stopTimeStamp
            ? 'stopTimeStamp: "' + find.stopTimeStamp + '"'
            : ''
        }
        ${find.requisition ? 'requisition: "' + find.requisition + '"' : ''}
        ${find.answer ? 'answer: "' + find.answer + '"' : ''}
        ${find.statusCode ? 'statusCode: ' + find.statusCode : ''}
        ${
          find.remoteAddress
            ? 'remoteAddress: "' + find.remoteAddress + '"'
            : ''
        }
        ${find.user ? 'user: "' + find.user + '"' : ''}
      }     
      ){
       timeStamp
       user
       requisition
       answer
       statusCode
       remoteAddress   
        
  }
    }
  `
    return api({
      method: 'post',
      data: {
        query,
      },
    })
      .then(({ data }) => {
        const { allSystemLogs } = data.data || {}
        resolve(
          allSystemLogs,
          //Feito tratamento para não deixar o device null pois o
          // Sistema não estava aceitando.
        )
      })
      .catch(() => {
        reject()
      })
  })
}

function* allSystemLogs(action) {
  const data = yield call(allSystemLogsRequest, action.payload)
  if (!data.result) {
    yield put(Actions.allSystemLogsFailure(data))
  } else {
    yield put(Actions.allSystemLogsSuccess(data))
  }
}

export default all([takeLatest(types.SYSTEM_LOGS_REQUEST, allSystemLogs)])
