import { SecurityPositionsTypescript } from '../../../typescript'
import * as types from '../../types'

const securityPosition = {}

export function oneSecurityPositionRequest(): {
  type: string
  payload: SecurityPositionsTypescript
} {
  return {
    type: types.ONE_SECURITY_POSITION_REQUEST,
    payload: securityPosition,
  }
}
export function oneSecurityPositionSuccess(): { type: string } {
  return {
    type: types.ONE_SECURITY_POSITION_SUCCESS,
  }
}
export function oneSecurityPositionFailure(): { type: string } {
  return {
    type: types.ONE_SECURITY_POSITION_FAILURE,
  }
}

export function allSecurityPositionsRequest(): {
  type: string
  payload: SecurityPositionsTypescript
} {
  return {
    type: types.ALL_SECURITY_POSITIONS_REQUEST,
    payload: securityPosition,
  }
}
export function allSecurityPositionsSuccess(
  data: SecurityPositionsTypescript[],
): {
  type: string
  payload: SecurityPositionsTypescript[]
} {
  return {
    type: types.ALL_SECURITY_POSITIONS_SUCCESS,
    payload: data,
  }
}
export function allSecurityPositionsFailure(): { type: string } {
  return {
    type: types.ALL_SECURITY_POSITIONS_FAILURE,
  }
}

export function addSecurityPositionRequest(data: SecurityPositionsTypescript): {
  type: string
  payload: SecurityPositionsTypescript
} {
  return {
    type: types.ADD_SECURITY_POSITION_REQUEST,
    payload: data,
  }
}
export function addSecurityPositionSuccess(): {
  type: string
} {
  return {
    type: types.ALL_SECURITY_POSITIONS_SUCCESS,
  }
}
export function addSecurityPositionsFailure(): { type: string } {
  return {
    type: types.ALL_SECURITY_POSITIONS_FAILURE,
  }
}
