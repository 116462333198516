import {
  FindSystemLogsTypescript,
  SystemLogsTypescript,
} from '../../../../typescript/logs'

import * as types from '../../../types'

export function allSystemLogsRequest(find: FindSystemLogsTypescript): {
  type: string
  payload: FindSystemLogsTypescript
} {
  return {
    type: types.SYSTEM_LOGS_REQUEST,
    payload: find,
  }
}
export function allSystemLogsSuccess(data: SystemLogsTypescript): {
  type: string
  payload: SystemLogsTypescript
} {
  return {
    type: types.SYSTEM_LOGS_SUCCESS,
    payload: data,
  }
}
export function allSystemLogsFailure(data: SystemLogsTypescript): {
  type: string
  payload: SystemLogsTypescript
} {
  return {
    type: types.SYSTEM_LOGS_FAILURE,
    payload: data,
  }
}
