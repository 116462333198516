import { combineReducers } from 'redux'
import changeState from './app'
import dashboardCardsReducer from './dashboardCards'
import branchReducer from './branches'
import deviceReducer from './devices'
import deviceTypeReducer from './deviceTypes'
import temperatureReducer from './temperatures'
import securityPositionReducer from './securityPositions'
import economicGroupReducer from './economicGroups'
import positionReducer from './positions'
import userReducer from './users'
import loginReducer from './login'
import resellerReducer from './resellers'
import profileReducer from './profiles'
import newProfileReducer from './newProfiles'
import commandReducer from './commands'
import eventTypeReducer from './eventTypes'
import { reportTemperatureDetailsReducer } from './reports'
import { systemLogsReducer } from './logs'
import menusReducer from './menus'
import departmentsReducer from './departments'
import sectionsReducer from './sections'

export type AppState = ReturnType<typeof rootReducer>

const rootReducer = combineReducers({
  app: changeState,
  dashboardCards: dashboardCardsReducer,
  branches: branchReducer,
  devices: deviceReducer,
  economicGroups: economicGroupReducer,
  positions: positionReducer,
  securityPositions: securityPositionReducer,
  temperatures: temperatureReducer,
  users: userReducer,
  login: loginReducer,
  deviceTypes: deviceTypeReducer,
  resellers: resellerReducer,
  profiles: profileReducer,
  newProfiles: newProfileReducer,
  commands: commandReducer,
  eventTypes: eventTypeReducer,
  reportTemperatureDetails: reportTemperatureDetailsReducer,
  systemLogs: systemLogsReducer,
  menus: menusReducer,
  sections: sectionsReducer,
  departments: departmentsReducer,
})

export default rootReducer
