// DASHBOARD CARDS
export const DASHBOARD_CARDS_REQUEST = 'DASHBOARD_CARDS_REQUEST'
export const DASHBOARD_CARDS_SUCCESS = 'DASHBOARD_CARDS_SUCCESS'
export const DASHBOARD_CARDS_FAILURE = 'DASHBOARD_CARDS_FAILURE'
export const DASHBOARD_CARDS_GROUPS_REQUEST = 'DASHBOARD_CARDS_GROUPS_REQUEST'
export const DASHBOARD_CARDS_GROUPS_SUCCESS = 'DASHBOARD_CARDS_GROUPS_SUCCESS'
export const DASHBOARD_CARDS_GROUPS_FAILURE = 'DASHBOARD_CARDS_GROUPS_FAILURE'
export const DASHBOARD_CARDS_DEVICES_REQUEST = 'DASHBOARD_CARDS_DEVICES_REQUEST'
export const DASHBOARD_CARDS_DEVICES_SUCCESS = 'DASHBOARD_CARDS_DEVICES_SUCCESS'
export const DASHBOARD_CARDS_DEVICES_FAILURE = 'DASHBOARD_CARDS_DEVICES_FAILURE'
export const DASHBOARD_CARDS_ONLINE_OFFLINE_REQUEST =
  'DASHBOARD_CARDS_ONLINE_OFFLINE_REQUEST'
export const DASHBOARD_CARDS_ONLINE_OFFLINE_SUCCESS =
  'DASHBOARD_CARDS_ONLINE_OFFLINE_SUCCESS'
export const DASHBOARD_CARDS_ONLINE_OFFLINE_FAILURE =
  'DASHBOARD_CARDS_ONLINE_OFFLINE_FAILURE'
export const DASHBOARD_CARDS_ALERTS_FAIL_REQUEST =
  'DASHBOARD_CARDS_ALERTS_FAIL_REQUEST'
export const DASHBOARD_CARDS_ALERTS_FAIL_SUCCESS =
  'DASHBOARD_CARDS_ALERTS_FAIL_SUCCESS'
export const DASHBOARD_CARDS_ALERTS_FAIL_FAILURE =
  'DASHBOARD_CARDS_ALERTS_FAIL_FAILURE'

// BRANCHES Consts
export const ALL_BRANCHES_REQUEST = 'ALL_BRANCHES_REQUEST'
export const ALL_BRANCHES_SUCCESS = 'ALL_BRANCHES_SUCCESS'
export const ALL_BRANCHES_FAILURE = 'ALL_BRANCHES_FAILURE'
export const ALL_BRANCHES_BY_GROUP_REQUEST = 'ALL_BRANCHES_BY_GROUP_REQUEST'
export const ALL_BRANCHES_BY_GROUP_SUCCESS = 'ALL_BRANCHES_BY_GROUP_SUCCESS'
export const ALL_BRANCHES_BY_GROUP_FAILURE = 'ALL_BRANCHES_BY_GROUP_FAILURE'
export const ALL_BRANCHES_BY_USER_REQUEST = 'ALL_BRANCHES_BY_USER_REQUEST'
export const ALL_BRANCHES_BY_USER_SUCCESS = 'ALL_BRANCHES_BY_USER_SUCCESS'
export const ALL_BRANCHES_BY_USER_FAILURE = 'ALL_BRANCHES_BY_USER_FAILURE'
export const ADD_BRANCH_REQUEST = 'ADD_BRANCH_REQUEST'
export const ADD_BRANCH_SUCCESS = 'ADD_BRANCH_SUCCESS'
export const ADD_BRANCH_FAILURE = 'ADD_BRANCH_FAILURE'
export const DEL_BRANCH_REQUEST = 'DEL_BRANCH_REQUEST'
export const DEL_BRANCH_SUCCESS = 'DEL_BRANCH_SUCCESS'
export const DEL_BRANCH_FAILURE = 'DEL_BRANCH_FAILURE'
export const UPD_BRANCH_REQUEST = 'UPD_BRANCH_REQUEST'
export const UPD_BRANCH_SUCCESS = 'UPD_BRANCH_SUCCESS'
export const UPD_BRANCH_FAILURE = 'UPD_BRANCH_FAILURE'

// Devices Consts
export const ONE_DEVICE_REQUEST = 'ONE_DEVICE_REQUEST'
export const ONE_DEVICE_SUCCESS = 'ONE_DEVICE_SUCCESS'
export const ONE_DEVICE_FAILURE = 'ONE_DEVICE_FAILURE'
export const ALL_DEVICES_REQUEST = 'ALL_DEVICES_REQUEST'
export const ALL_DEVICES_SUCCESS = 'ALL_DEVICES_SUCCESS'
export const ALL_DEVICES_FAILURE = 'ALL_DEVICES_FAILURE'
export const ALL_DEVICES_BY_GROUP_REQUEST = 'ALL_DEVICES_BY_GROUP_REQUEST'
export const ALL_DEVICES_BY_GROUP_SUCCESS = 'ALL_DEVICES_BY_GROUP_SUCCESS'
export const ALL_DEVICES_BY_GROUP_FAILURE = 'ALL_DEVICES_BY_GROUP_FAILURE'
export const DASHBOARD_CARD_DEVICES_REQUEST = 'DASHBOARD_CARD_DEVICES_REQUEST'
export const DASHBOARD_CARD_DEVICES_SUCCESS = 'DASHBOARD_CARD_DEVICES_SUCCESS'
export const DASHBOARD_CARD_DEVICES_FAILURE = 'DASHBOARD_CARD_DEVICES_FAILURE'
export const ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST'
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS'
export const ADD_DEVICE_FAILURE = 'ADD_DEVICE_FAILURE'
export const DEL_DEVICE_REQUEST = 'DEL_DEVICE_REQUEST'
export const DEL_DEVICE_SUCCESS = 'DEL_DEVICE_SUCCESS'
export const DEL_DEVICE_FAILURE = 'DEL_DEVICE_FAILURE'
export const UPD_DEVICE_REQUEST = 'UPD_DEVICE_REQUEST'
export const UPD_DEVICE_SUCCESS = 'UPD_DEVICE_SUCCESS'
export const UPD_DEVICE_FAILURE = 'UPD_DEVICE_FAILURE'
export const UPD_GROUP_DEVICE_REQUEST = 'UPD_GROUP_DEVICE_REQUEST'
export const UPD_GROUP_DEVICE_SUCCESS = 'UPD_GROUP_DEVICE_SUCCESS'
export const UPD_GROUP_DEVICE_FAILURE = 'UPD_GROUP_DEVICE_FAILURE'

// Devices Type Consts
export const ONE_DEVICE_TYPE_REQUEST = 'ONE_DEVICE_TYPE_REQUEST'
export const ONE_DEVICE_TYPE_SUCCESS = 'ONE_DEVICE_TYPE_SUCCESS'
export const ONE_DEVICE_TYPE_FAILURE = 'ONE_DEVICE_TYPE_FAILURE'
export const ALL_DEVICE_TYPES_REQUEST = 'ALL_DEVICES_TYPE_REQUEST'
export const ALL_DEVICE_TYPES_SUCCESS = 'ALL_DEVICES_TYPE_SUCCESS'
export const ALL_DEVICE_TYPES_FAILURE = 'ALL_DEVICES_TYPE_FAILURE'
export const TYPES_DEVICE_TYPES_REQUEST = 'TYPES_DEVICES_TYPE_REQUEST'
export const TYPES_DEVICE_TYPES_SUCCESS = 'TYPES_DEVICES_TYPE_SUCCESS'
export const TYPES_DEVICE_TYPES_FAILURE = 'TYPES_DEVICES_TYPE_FAILURE'
export const ADD_DEVICE_TYPE_REQUEST = 'ADD_DEVICE_TYPE_REQUEST'
export const ADD_DEVICE_TYPE_SUCCESS = 'ADD_DEVICE_TYPE_SUCCESS'
export const ADD_DEVICE_TYPE_FAILURE = 'ADD_DEVICE_TYPE_FAILURE'
export const DEL_DEVICE_TYPE_REQUEST = 'DEL_DEVICE_TYPE_REQUEST'
export const DEL_DEVICE_TYPE_SUCCESS = 'DEL_DEVICE_TYPE_SUCCESS'
export const DEL_DEVICE_TYPE_FAILURE = 'DEL_DEVICE_TYPE_FAILURE'
export const UPD_DEVICE_TYPE_REQUEST = 'UPD_DEVICE_TYPE_REQUEST'
export const UPD_DEVICE_TYPE_SUCCESS = 'UPD_DEVICE_TYPE_SUCCESS'
export const UPD_DEVICE_TYPE_FAILURE = 'UPD_DEVICE_TYPE_FAILURE'

// Temperatures Consts
export const ONE_TEMPERATURE_REQUEST = 'ONE_TEMPERATURE_REQUEST'
export const ONE_TEMPERATURE_SUCCESS = 'ONE_TEMPERATURE_SUCCESS'
export const ONE_TEMPERATURE_FAILURE = 'ONE_TEMPERATURE_FAILURE'
export const ALL_TEMPERATURES_REQUEST = 'ALL_TEMPERATURES_REQUEST'
export const ALL_TEMPERATURES_SUCCESS = 'ALL_TEMPERATURES_SUCCESS'
export const ALL_TEMPERATURES_FAILURE = 'ALL_TEMPERATURES_FAILURE'
export const ONE_DASHBOARD_TEMPERATURE_REQUEST =
  'ONE_DASHBOARD_TEMPERATURE_REQUEST'
export const ONE_DASHBOARD_TEMPERATURE_SUCCESS =
  'ONE_DASHBOARD_TEMPERATURE_SUCCESS'
export const ONE_DASHBOARD_TEMPERATURE_FAILURE =
  'ONE_DASHBOARD_TEMPERATURE_FAILURE'
export const LAST_TEMPERATURES_READ_REQUEST = 'LAST_TEMPERATURES_READ_REQUEST'
export const LAST_TEMPERATURES_READ_SUCCESS = 'LAST_TEMPERATURES_READ_SUCCESS'
export const LAST_TEMPERATURES_READ_FAILURE = 'LAST_TEMPERATURES_READ_FAILURE'
export const LAST_TEMPERATURES_READ_GRID_REQUEST =
  'LAST_TEMPERATURES_READ_GRID_REQUEST'
export const LAST_TEMPERATURES_READ_GRID_SUCCESS =
  'LAST_TEMPERATURES_READ_GRID_SUCCESS'
export const LAST_TEMPERATURES_READ_GRID_FAILURE =
  'LAST_TEMPERATURES_READ_GRID_FAILURE'
export const LAST_TEMPERATURES_READ_GRID_RESET_REQUEST =
  'LAST_TEMPERATURES_READ_GRID_RESET_REQUEST'
export const LAST_TEMPERATURES_READ_GRID_RESET_SUCCESS =
  'LAST_TEMPERATURES_READ_GRID_RESET_SUCCESS'
export const LAST_TEMPERATURES_READ_GRID_RESET_FAILURE =
  'LAST_TEMPERATURES_READ_GRID_RESET_FAILURE'
export const ADD_TEMPERATURE_REQUEST = 'ADD_TEMPERATURE_REQUEST'
export const ADD_TEMPERATURE_SUCCESS = 'ADD_TEMPERATURE_SUCCESS'
export const ADD_TEMPERATURE_FAILURE = 'ADD_TEMPERATURE_FAILURE'
export const DEL_TEMPERATURE_REQUEST = 'DEL_TEMPERATURE_REQUEST'
export const DEL_TEMPERATURE_SUCCESS = 'DEL_TEMPERATURE_SUCCESS'
export const DEL_TEMPERATURE_FAILURE = 'DEL_TEMPERATURE_FAILURE'
export const UPD_TEMPERATURE_REQUEST = 'UPD_TEMPERATURE_REQUEST'
export const UPD_TEMPERATURE_SUCCESS = 'UPD_TEMPERATURE_SUCCESS'
export const UPD_TEMPERATURE_FAILURE = 'UPD_TEMPERATURE_FAILURE'

// Employees Consts
export const ONE_EMPLOYEE_REQUEST = 'ONE_EMPLOYEE_REQUEST'
export const ONE_EMPLOYEE_SUCCESS = 'ONE_EMPLOYEE_SUCCESS'
export const ONE_EMPLOYEE_FAILURE = 'ONE_EMPLOYEE_FAILURE'
export const ALL_EMPLOYEES_REQUEST = 'ALL_EMPLOYEES_REQUEST'
export const ALL_EMPLOYEES_SUCCESS = 'ALL_EMPLOYEES_SUCCESS'
export const ALL_EMPLOYEES_FAILURE = 'ALL_EMPLOYEES_FAILURE'
export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE'
export const DEL_EMPLOYEE_REQUEST = 'DEL_EMPLOYEE_REQUEST'
export const DEL_EMPLOYEE_SUCCESS = 'DEL_EMPLOYEE_SUCCESS'
export const DEL_EMPLOYEE_FAILURE = 'DEL_EMPLOYEE_FAILURE'
export const UPD_EMPLOYEE_REQUEST = 'UPD_EMPLOYEE_REQUEST'
export const UPD_EMPLOYEE_SUCCESS = 'UPD_EMPLOYEE_SUCCESS'
export const UPD_EMPLOYEE_FAILURE = 'UPD_EMPLOYEE_FAILURE'

// Positions Consts
export const ONE_POSITION_REQUEST = 'ONE_POSITION_REQUEST'
export const ONE_POSITION_SUCCESS = 'ONE_POSITION_SUCCESS'
export const ONE_POSITION_FAILURE = 'ONE_POSITION_FAILURE'
export const ALL_POSITIONS_REQUEST = 'ALL_POSITIONS_REQUEST'
export const ALL_POSITIONS_SUCCESS = 'ALL_POSITIONS_SUCCESS'
export const ALL_POSITIONS_FAILURE = 'ALL_POSITIONS_FAILURE'
export const ADD_POSITION_REQUEST = 'ADD_POSITION_REQUEST'
export const ADD_POSITION_SUCCESS = 'ADD_POSITION_SUCCESS'
export const ADD_POSITION_FAILURE = 'ADD_POSITION_FAILURE'
export const DEL_POSITION_REQUEST = 'DEL_POSITION_REQUEST'
export const DEL_POSITION_SUCCESS = 'DEL_POSITION_SUCCESS'
export const DEL_POSITION_FAILURE = 'DEL_POSITION_FAILURE'
export const UPD_POSITION_REQUEST = 'UPD_POSITION_REQUEST'
export const UPD_POSITION_SUCCESS = 'UPD_POSITION_SUCCESS'
export const UPD_POSITION_FAILURE = 'UPD_POSITION_FAILURE'

// Profile Consts
export const ONE_PROFILE_REQUEST = 'ONE_PROFILE_REQUEST'
export const ONE_PROFILE_SUCCESS = 'ONE_PROFILE_SUCCESS'
export const ONE_PROFILE_FAILURE = 'ONE_PROFILE_FAILURE'
export const ALL_PROFILES_REQUEST = 'ALL_PROFILES_REQUEST'
export const ALL_PROFILES_SUCCESS = 'ALL_PROFILES_SUCCESS'
export const ALL_PROFILES_FAILURE = 'ALL_PROFILES_FAILURE'
export const ADD_PROFILE_REQUEST = 'ADD_PROFILE_REQUEST'
export const ADD_PROFILE_SUCCESS = 'ADD_PROFILE_SUCCESS'
export const ADD_PROFILE_FAILURE = 'ADD_PROFILE_FAILURE'
export const DEL_PROFILE_REQUEST = 'DEL_PROFILE_REQUEST'
export const DEL_PROFILE_SUCCESS = 'DEL_PROFILE_SUCCESS'
export const DEL_PROFILE_FAILURE = 'DEL_PROFILE_FAILURE'
export const UPD_PROFILE_REQUEST = 'UPD_PROFILE_REQUEST'
export const UPD_PROFILE_SUCCESS = 'UPD_PROFILE_SUCCESS'
export const UPD_PROFILE_FAILURE = 'UPD_PROFILE_FAILURE'

export const ONE_NEW_PROFILE_REQUEST = 'ONE_NEW_PROFILE_REQUEST'
export const ONE_NEW_PROFILE_SUCCESS = 'ONE_NEW_PROFILE_SUCCESS'
export const ONE_NEW_PROFILE_FAILURE = 'ONE_NEW_PROFILE_FAILURE'
export const ALL_NEW_PROFILES_REQUEST = 'ALL_NEW_PROFILES_REQUEST'
export const ALL_NEW_PROFILES_SUCCESS = 'ALL_NEW_PROFILES_SUCCESS'
export const ALL_NEW_PROFILES_FAILURE = 'ALL_NEW_PROFILES_FAILURE'
export const ALL_NEW_PROFILES_TREE_REQUEST = 'ALL_NEW_PROFILES_TREE_REQUEST'
export const ALL_NEW_PROFILES_TREE_SUCCESS = 'ALL_NEW_PROFILES_TREE_SUCCESS'
export const ALL_NEW_PROFILES_TREE_FAILURE = 'ALL_NEW_PROFILES_TREE_FAILURE'
export const ADD_NEW_PROFILE_REQUEST = 'ADD_NEW_PROFILE_REQUEST'
export const ADD_NEW_PROFILE_SUCCESS = 'ADD_NEW_PROFILE_SUCCESS'
export const ADD_NEW_PROFILE_FAILURE = 'ADD_NEW_PROFILE_FAILURE'
export const DEL_NEW_PROFILE_REQUEST = 'DEL_NEW_PROFILE_REQUEST'
export const DEL_NEW_PROFILE_SUCCESS = 'DEL_NEW_PROFILE_SUCCESS'
export const DEL_NEW_PROFILE_FAILURE = 'DEL_NEW_PROFILE_FAILURE'
export const UPD_NEW_PROFILE_REQUEST = 'UPD_NEW_PROFILE_REQUEST'
export const UPD_NEW_PROFILE_SUCCESS = 'UPD_NEW_PROFILE_SUCCESS'
export const UPD_NEW_PROFILE_FAILURE = 'UPD_NEW_PROFILE_FAILURE'

// Security Positions Consts
export const ONE_SECURITY_POSITION_REQUEST = 'ONE_SECURITY_POSITION_REQUEST'
export const ONE_SECURITY_POSITION_SUCCESS = 'ONE_SECURITY_POSITION_SUCCESS'
export const ONE_SECURITY_POSITION_FAILURE = 'ONE_SECURITY_POSITION_FAILURE'
export const ALL_SECURITY_POSITIONS_REQUEST = 'ALL_SECURITY_POSITIONS_REQUEST'
export const ALL_SECURITY_POSITIONS_SUCCESS = 'ALL_SECURITY_POSITIONS_SUCCESS'
export const ALL_SECURITY_POSITIONS_FAILURE = 'ALL_SECURITY_POSITIONS_FAILURE'
export const ADD_SECURITY_POSITION_REQUEST = 'ADD_SECURITY_POSITION_REQUEST'
export const ADD_SECURITY_POSITION_SUCCESS = 'ADD_SECURITY_POSITION_SUCCESS'
export const ADD_SECURITY_POSITION_FAILURE = 'ADD_SECURITY_POSITION_FAILURE'
export const DEL_SECURITY_POSITION_REQUEST = 'DEL_SECURITY_POSITION_REQUEST'
export const DEL_SECURITY_POSITION_SUCCESS = 'DEL_SECURITY_POSITION_SUCCESS'
export const DEL_SECURITY_POSITION_FAILURE = 'DEL_SECURITY_POSITION_FAILURE'
export const UPD_SECURITY_POSITION_REQUEST = 'UPD_SECURITY_POSITION_REQUEST'
export const UPD_SECURITY_POSITION_SUCCESS = 'UPD_SECURITY_POSITION_SUCCESS'
export const UPD_SECURITY_POSITION_FAILURE = 'UPD_SECURITY_POSITION_FAILURE'

// Last Position Consts
export const ONE_LAST_POSITION_REQUEST = 'ONE_LAST_POSITION_REQUEST'
export const ONE_LAST_POSITION_SUCCESS = 'ONE_LAST_POSITION_SUCCESS'
export const ONE_LAST_POSITION_FAILURE = 'ONE_LAST_POSITION_FAILURE'
export const ALL_LAST_POSITIONS_REQUEST = 'ALL_LAST_POSITIONS_REQUEST'
export const ALL_LAST_POSITIONS_SUCCESS = 'ALL_LAST_POSITIONS_SUCCESS'
export const ALL_LAST_POSITIONS_FAILURE = 'ALL_LAST_POSITIONS_FAILURE'
export const ADD_LAST_POSITION_REQUEST = 'ADD_LAST_POSITION_REQUEST'
export const ADD_LAST_POSITION_SUCCESS = 'ADD_LAST_POSITION_SUCCESS'
export const ADD_LAST_POSITION_FAILURE = 'ADD_LAST_POSITION_FAILURE'
export const DEL_LAST_POSITION_REQUEST = 'DEL_LAST_POSITION_REQUEST'
export const DEL_LAST_POSITION_SUCCESS = 'DEL_LAST_POSITION_SUCCESS'
export const DEL_LAST_POSITION_FAILURE = 'DEL_LAST_POSITION_FAILURE'
export const UPD_LAST_POSITION_REQUEST = 'UPD_LAST_POSITION_REQUEST'
export const UPD_LAST_POSITION_SUCCESS = 'UPD_LAST_POSITION_SUCCESS'
export const UPD_LAST_POSITION_FAILURE = 'UPD_LAST_POSITION_FAILURE'

// Economic Groups Consts
export const ONE_ECONOMIC_GROUP_REQUEST = 'ONE_ECONOMIC_GROUP_REQUEST'
export const ONE_ECONOMIC_GROUP_SUCCESS = 'ONE_ECONOMIC_GROUP_SUCCESS'
export const ONE_ECONOMIC_GROUP_FAILURE = 'ONE_ECONOMIC_GROUP_FAILURE'
export const ACTUAL_ECONOMIC_GROUP_REQUEST = 'ACTUAL_ECONOMIC_GROUP_REQUEST'
export const ACTUAL_ECONOMIC_GROUP_RESET = 'ACTUAL_ECONOMIC_GROUP_RESET'
export const ACTUAL_ECONOMIC_GROUP_SUCCESS = 'ACTUAL_ECONOMIC_GROUP_SUCCESS'
export const ACTUAL_ECONOMIC_GROUP_FAILURE = 'ACTUAL_ECONOMIC_GROUP_FAILURE'
export const ALL_ECONOMIC_GROUPS_AUTHORIZED_REQUEST =
  'ALL_ECONOMIC_GROUPS_AUTHORIZED_REQUEST'
export const ALL_ECONOMIC_GROUPS_AUTHORIZED_SUCCESS =
  'ALL_ECONOMIC_GROUPS_AUTHORIZED_SUCCESS'
export const ALL_ECONOMIC_GROUPS_AUTHORIZED_FAILURE =
  'ALL_ECONOMIC_GROUPS_AUTHORIZED_FAILURE'
export const ALL_ECONOMIC_GROUPS_REQUEST = 'ALL_ECONOMIC_GROUPS_REQUEST'
export const ALL_ECONOMIC_GROUPS_SUCCESS = 'ALL_ECONOMIC_GROUPS_SUCCESS'
export const ALL_ECONOMIC_GROUPS_FAILURE = 'ALL_ECONOMIC_GROUPS_FAILURE'
export const ALL_ECONOMIC_GROUPS_TO_RESSELERS_REQUEST =
  'ALL_ECONOMIC_GROUPS_TO_RESSELERS_REQUEST'
export const ALL_ECONOMIC_GROUPS_TO_RESSELERS_SUCCESS =
  'ALL_ECONOMIC_GROUPS_TO_RESSELERS_SUCCESS'
export const ALL_ECONOMIC_GROUPS_TO_RESSELERS_FAILURE =
  'ALL_ECONOMIC_GROUPS_TO_RESSELERS_FAILURE'
export const ADD_ECONOMIC_GROUP_REQUEST = 'ADD_ECONOMIC_GROUP_REQUEST'
export const ADD_ECONOMIC_GROUP_SUCCESS = 'ADD_ECONOMIC_GROUP_SUCCESS'
export const ADD_ECONOMIC_GROUP_FAILURE = 'ADD_ECONOMIC_GROUP_FAILURE'
export const DEL_ECONOMIC_GROUP_REQUEST = 'DEL_ECONOMIC_GROUP_REQUEST'
export const DEL_ECONOMIC_GROUP_SUCCESS = 'DEL_ECONOMIC_GROUP_SUCCESS'
export const DEL_ECONOMIC_GROUP_FAILURE = 'DEL_ECONOMIC_GROUP_FAILURE'
export const UPD_ECONOMIC_GROUP_REQUEST = 'UPD_ECONOMIC_GROUP_REQUEST'
export const UPD_ECONOMIC_GROUP_SUCCESS = 'UPD_ECONOMIC_GROUP_SUCCESS'
export const UPD_ECONOMIC_GROUP_FAILURE = 'UPD_ECONOMIC_GROUP_FAILURE'

// Users Consts
export const ONE_USER_REQUEST = 'ONE_USER_REQUEST'
export const ONE_USER_SUCCESS = 'ONE_USER_SUCCESS'
export const ONE_USER_FAILURE = 'ONE_USER_FAILURE'
export const ONE_USER_LOGIN_REQUEST = 'ONE_USER_LOGIN_REQUEST'
export const ONE_USER_LOGIN_SUCCESS = 'ONE_USER_LOGIN_SUCCESS'
export const ONE_USER_LOGIN_FAILURE = 'ONE_USER_LOGIN_FAILURE'
export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const ALL_USERS_FAILURE = 'ALL_USERS_FAILURE'
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE'
export const DEL_USER_REQUEST = 'DEL_USER_REQUEST'
export const DEL_USER_SUCCESS = 'DEL_USER_SUCCESS'
export const DEL_USER_FAILURE = 'DEL_USER_FAILURE'
export const UPD_USER_REQUEST = 'UPD_USER_REQUEST'
export const UPD_USER_SUCCESS = 'UPD_USER_SUCCESS'
export const UPD_USER_FAILURE = 'UPD_USER_FAILURE'
export const UPD_USER_GROUP_REQUEST = 'UPD_USER_GROUP_REQUEST'
export const UPD_USER_GROUP_SUCCESS = 'UPD_USER_GROUP_SUCCESS'
export const UPD_USER_GROUP_FAILURE = 'UPD_USER_GROUP_FAILURE'

// Login Consts
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_CHANGE_PASSWORD_REQUEST = 'LOGIN_CHANGE_PASSWORD_REQUEST'
export const LOGIN_CHANGE_PASSWORD_SUCCESS = 'LOGIN_CHANGE_PASSWORD_SUCCESS'
export const LOGIN_CHANGE_PASSWORD_FAILURE = 'LOGIN_CHANGE_PASSWORD_FAILURE'
export const LOGIN_CREATE_TOKEN_REQUEST = 'LOGIN_CREATE_TOKEN_REQUEST'
export const LOGIN_CREATE_TOKEN_RESET = 'LOGIN_CREATE_TOKEN_RESET'
export const LOGIN_CREATE_TOKEN_SUCCESS = 'LOGIN_CREATE_TOKEN_SUCCESS'
export const LOGIN_CREATE_TOKEN_FAILURE = 'LOGIN_CREATE_TOKEN_FAILURE'
export const LOGIN_CHANGE_PASSWORD_RESET = 'LOGIN_CHANGE_PASSWORD_RESET'
export const LOGIN_RECOVERY_SEND_EMAIL_REQUEST =
  'LOGIN_RECOVERY_SEND_EMAIL_REQUEST'
export const LOGIN_RECOVERY_SEND_EMAIL_SUCCESS =
  'LOGIN_RECOVERY_SEND_EMAIL_SUCCESS'
export const LOGIN_RECOVERY_SEND_EMAIL_FAILURE =
  'LOGIN_RECOVERY_SEND_EMAIL_FAILURE'
export const LOGIN_RECOVERY_SEND_EMAIL_RESET = 'LOGIN_RECOVERY_SEND_EMAIL_RESET'

// Logout Consts
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

// Login Consts
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

// Login Consts
export const RECOVERY_PASSWORD_REQUEST = 'RECOVERY_PASSWORD_REQUEST'
export const RECOVERY_PASSWORD_SUCCESS = 'RECOVERY_PASSWORD_SUCCESS'
export const RECOVERY_PASSWORD_FAILURE = 'RECOVERY_PASSWORD_FAILURE'

// ReseLlers Consts
export const ONE_RESELLER_REQUEST = 'ONE_RESELLER_REQUEST'
export const ONE_RESELLER_SUCCESS = 'ONE_RESELLER_SUCCESS'
export const ONE_RESELLER_FAILURE = 'ONE_RESELLER_FAILURE'
export const ALL_RESELLERS_REQUEST = 'ALL_RESELLERS_REQUEST'
export const ALL_RESELLERS_SUCCESS = 'ALL_RESELLERS_SUCCESS'
export const ALL_RESELLERS_FAILURE = 'ALL_RESELLERS_FAILURE'
export const ALLOWED_RESELLERS_REQUEST = 'ALLOWED_RESELLERS_REQUEST'
export const ALLOWED_RESELLERS_SUCCESS = 'ALLOWED_RESELLERS_SUCCESS'
export const ALLOWED_RESELLERS_FAILURE = 'ALLOWED_RESELLERS_FAILURE'
export const ADD_RESELLER_REQUEST = 'ADD_RESELLER_REQUEST'
export const ADD_RESELLER_SUCCESS = 'ADD_RESELLER_SUCCESS'
export const ADD_RESELLER_FAILURE = 'ADD_RESELLER_FAILURE'
export const DEL_RESELLER_REQUEST = 'DEL_RESELLER_REQUEST'
export const DEL_RESELLER_SUCCESS = 'DEL_RESELLER_SUCCESS'
export const DEL_RESELLER_FAILURE = 'DEL_RESELLER_FAILURE'
export const UPD_RESELLER_REQUEST = 'UPD_RESELLER_REQUEST'
export const UPD_RESELLER_SUCCESS = 'UPD_RESELLER_SUCCESS'
export const UPD_RESELLER_FAILURE = 'UPD_RESELLER_FAILURE'

// Commands Consts
export const ONE_COMMAND_REQUEST = 'ONE_COMMAND_REQUEST'
export const ONE_COMMAND_SUCCESS = 'ONE_COMMAND_SUCCESS'
export const ONE_COMMAND_FAILURE = 'ONE_COMMAND_FAILURE'
export const ALL_COMMANDS_REQUEST = 'ALL_COMMANDS_REQUEST'
export const ALL_COMMANDS_SUCCESS = 'ALL_COMMANDS_SUCCESS'
export const ALL_COMMANDS_FAILURE = 'ALL_COMMANDS_FAILURE'
export const ADD_COMMAND_REQUEST = 'ADD_COMMAND_REQUEST'
export const ADD_COMMAND_SUCCESS = 'ADD_COMMAND_SUCCESS'
export const ADD_COMMAND_FAILURE = 'ADD_COMMAND_FAILURE'
export const DEL_COMMAND_REQUEST = 'DEL_COMMAND_REQUEST'
export const DEL_COMMAND_SUCCESS = 'DEL_COMMAND_SUCCESS'
export const DEL_COMMAND_FAILURE = 'DEL_COMMAND_FAILURE'
export const UPD_COMMAND_REQUEST = 'UPD_COMMAND_REQUEST'
export const UPD_COMMAND_SUCCESS = 'UPD_COMMAND_SUCCESS'
export const UPD_COMMAND_FAILURE = 'UPD_COMMAND_FAILURE'

// Events
export const ONE_EVENT_TYPE_REQUEST = 'ONE_EVENT_TYPE_REQUEST'
export const ONE_EVENT_TYPE_SUCCESS = 'ONE_EVENT_TYPE_SUCCESS'
export const ONE_EVENT_TYPE_FAILURE = 'ONE_EVENT_TYPE_FAILURE'
export const ALL_EVENT_TYPES_REQUEST = 'ALL_EVENT_TYPES_REQUEST'
export const ALL_EVENT_TYPES_SUCCESS = 'ALL_EVENT_TYPES_SUCCESS'
export const ALL_EVENT_TYPES_FAILURE = 'ALL_EVENT_TYPES_FAILURE'
export const ADD_EVENT_TYPE_REQUEST = 'ADD_EVENT_TYPE_REQUEST'
export const ADD_EVENT_TYPE_SUCCESS = 'ADD_EVENT_TYPE_SUCCESS'
export const ADD_EVENT_TYPE_FAILURE = 'ADD_EVENT_TYPE_FAILURE'
export const DEL_EVENT_TYPE_REQUEST = 'DEL_EVENT_TYPE_REQUEST'
export const DEL_EVENT_TYPE_SUCCESS = 'DEL_EVENT_TYPE_SUCCESS'
export const DEL_EVENT_TYPE_FAILURE = 'DEL_EVENT_TYPE_FAILURE'
export const UPD_EVENT_TYPE_REQUEST = 'UPD_EVENT_TYPE_REQUEST'
export const UPD_EVENT_TYPE_SUCCESS = 'UPD_EVENT_TYPE_SUCCESS'
export const UPD_EVENT_TYPE_FAILURE = 'UPD_EVENT_TYPE_FAILURE'

// Reports
// temperatureDetails
export const ALL_REPORT_TEMPERATURE_DETAILS_REQUEST =
  'ALL_REPORT_TEMPERATURE_DETAILS_REQUEST'
export const ALL_REPORT_TEMPERATURE_DETAILS_SUCCESS =
  'ALL_REPORT_TEMPERATURE_DETAILS_SUCCESS'
export const ALL_REPORT_TEMPERATURE_DETAILS_FAILURE =
  'ALL_REPORT_TEMPERATURE_DETAILS_FAILURE'

// Report By Date
export const SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_REQUEST =
  'SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_REQUEST'
export const SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_SUCCESS =
  'SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_SUCCESS'
export const SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_FAILURE =
  'SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_FAILURE'

// System Logs
export const SYSTEM_LOGS_REQUEST = 'SYSTEM_LOGS_REQUEST'
export const SYSTEM_LOGS_SUCCESS = 'SYSTEM_LOGS_SUCCESS'
export const SYSTEM_LOGS_FAILURE = 'SYSTEM_LOGS_FAILURE'

// Menus
export const ONE_MENU_REQUEST = 'ONE_MENU_REQUEST'
export const ONE_MENU_SUCCESS = 'ONE_MENU_SUCCESS'
export const ONE_MENU_FAILURE = 'ONE_MENU_FAILURE'
export const ALL_MENUS_REQUEST = 'ALL_MENUS_REQUEST'
export const ALL_MENUS_SUCCESS = 'ALL_MENUS_SUCCESS'
export const ALL_MENUS_FAILURE = 'ALL_MENUS_FAILURE'
export const ADD_MENU_REQUEST = 'ADD_MENU_REQUEST'
export const ADD_MENU_SUCCESS = 'ADD_MENU_SUCCESS'
export const ADD_MENU_FAILURE = 'ADD_MENU_FAILURE'
export const DEL_MENU_REQUEST = 'DEL_MENU_REQUEST'
export const DEL_MENU_SUCCESS = 'DEL_MENU_SUCCESS'
export const DEL_MENU_FAILURE = 'DEL_MENU_FAILURE'
export const UPD_MENU_REQUEST = 'UPD_MENU_REQUEST'
export const UPD_MENU_SUCCESS = 'UPD_MENU_SUCCESS'
export const UPD_MENU_FAILURE = 'UPD_MENU_FAILURE'

// Menus
export const ONE_DEPARTMENT_REQUEST = 'ONE_DEPARTMENT_REQUEST'
export const ONE_DEPARTMENT_SUCCESS = 'ONE_DEPARTMENT_SUCCESS'
export const ONE_DEPARTMENT_FAILURE = 'ONE_DEPARTMENT_FAILURE'
export const ALL_DEPARTMENTS_REQUEST = 'ALL_DEPARTMENTS_REQUEST'
export const ALL_DEPARTMENTS_SUCCESS = 'ALL_DEPARTMENTS_SUCCESS'
export const ALL_DEPARTMENTS_FAILURE = 'ALL_DEPARTMENTS_FAILURE'
export const ADD_DEPARTMENT_REQUEST = 'ADD_DEPARTMENT_REQUEST'
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS'
export const ADD_DEPARTMENT_FAILURE = 'ADD_DEPARTMENT_FAILURE'
export const DEL_DEPARTMENT_REQUEST = 'DEL_DEPARTMENT_REQUEST'
export const DEL_DEPARTMENT_SUCCESS = 'DEL_DEPARTMENT_SUCCESS'
export const DEL_DEPARTMENT_FAILURE = 'DEL_DEPARTMENT_FAILURE'
export const UPD_DEPARTMENT_REQUEST = 'UPD_DEPARTMENT_REQUEST'
export const UPD_DEPARTMENT_SUCCESS = 'UPD_DEPARTMENT_SUCCESS'
export const UPD_DEPARTMENT_FAILURE = 'UPD_DEPARTMENT_FAILURE'

export const ONE_SECTION_REQUEST = 'ONE_SECTION_REQUEST'
export const ONE_SECTION_SUCCESS = 'ONE_SECTION_SUCCESS'
export const ONE_SECTION_FAILURE = 'ONE_SECTION_FAILURE'
export const ALL_SECTIONS_REQUEST = 'ALL_SECTIONS_REQUEST'
export const ALL_SECTIONS_SUCCESS = 'ALL_SECTIONS_SUCCESS'
export const ALL_SECTIONS_FAILURE = 'ALL_SECTIONS_FAILURE'
export const ADD_SECTION_REQUEST = 'ADD_SECTION_REQUEST'
export const ADD_SECTION_SUCCESS = 'ADD_SECTION_SUCCESS'
export const ADD_SECTION_FAILURE = 'ADD_SECTION_FAILURE'
export const DEL_SECTION_REQUEST = 'DEL_SECTION_REQUEST'
export const DEL_SECTION_SUCCESS = 'DEL_SECTION_SUCCESS'
export const DEL_SECTION_FAILURE = 'DEL_SECTION_FAILURE'
export const UPD_SECTION_REQUEST = 'UPD_SECTION_REQUEST'
export const UPD_SECTION_SUCCESS = 'UPD_SECTION_SUCCESS'
export const UPD_SECTION_FAILURE = 'UPD_SECTION_FAILURE'
