import { BranchesTypescript } from '../../../typescript'
import * as types from '../../types'

export function allBranchesRequest(branch: BranchesTypescript): {
  type: string
  payload: BranchesTypescript
} {
  return {
    type: types.ALL_BRANCHES_REQUEST,
    payload: branch,
  }
}
export function allBranchesSuccess(data: BranchesTypescript[]): {
  type: string
  payload: BranchesTypescript[]
} {
  return {
    type: types.ALL_BRANCHES_SUCCESS,
    payload: data,
  }
}
export function allBranchesFailure(): { type: string } {
  return {
    type: types.ALL_BRANCHES_FAILURE,
  }
}

export function allBranchesByUserRequest(userGroup: string): {
  type: string
  payload: string
} {
  return {
    type: types.ALL_BRANCHES_BY_USER_REQUEST,
    payload: userGroup,
  }
}

export function allBranchesByUserSuccess(data: BranchesTypescript[]): {
  type: string
  payload: BranchesTypescript[]
} {
  return {
    type: types.ALL_BRANCHES_BY_USER_SUCCESS,
    payload: data,
  }
}

export function allBranchesByUserFailure(): { type: string } {
  return {
    type: types.ALL_BRANCHES_BY_USER_FAILURE,
  }
}

export function allBranchesByGroupRequest(branch: BranchesTypescript): {
  type: string
  payload: BranchesTypescript
} {
  return {
    type: types.ALL_BRANCHES_BY_GROUP_REQUEST,
    payload: branch,
  }
}
export function allBranchesByGroupSuccess(data: BranchesTypescript[]): {
  type: string
  payload: BranchesTypescript[]
} {
  return {
    type: types.ALL_BRANCHES_BY_GROUP_SUCCESS,
    payload: data,
  }
}
export function allBranchesByGroupFailure(): { type: string } {
  return {
    type: types.ALL_BRANCHES_BY_GROUP_FAILURE,
  }
}

export function addBranchRequest(branch: BranchesTypescript): {
  type: string
  payload: BranchesTypescript
} {
  return {
    type: types.ADD_BRANCH_REQUEST,
    payload: branch,
  }
}
export function addBranchSuccess(data: BranchesTypescript): {
  type: string
  payload: BranchesTypescript
} {
  return {
    type: types.ADD_BRANCH_SUCCESS,
    payload: data,
  }
}
export function addBranchFailure(): { type: string } {
  return {
    type: types.ADD_BRANCH_FAILURE,
  }
}

export function updBranchRequest(branch: BranchesTypescript): {
  type: string
  payload: BranchesTypescript
} {
  return {
    type: types.UPD_BRANCH_REQUEST,
    payload: branch,
  }
}
export function updBranchSuccess(data: BranchesTypescript[]): {
  type: string
  payload: BranchesTypescript[]
} {
  return {
    type: types.UPD_BRANCH_SUCCESS,
    payload: data,
  }
}
export function updBranchFailure(data: BranchesTypescript): {
  type: string
  payload: BranchesTypescript
} {
  return {
    type: types.UPD_BRANCH_FAILURE,
    payload: data,
  }
}
