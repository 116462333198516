import { createSelector } from 'reselect'
import { SectionsTypescript } from '../../../typescript'

// Getters
const getSections = (state: { sections: { sections: SectionsTypescript[] } }) =>
  state.sections.sections
const addSection = (state: { sections: { addSection: SectionsTypescript } }) =>
  state.sections.addSection
const updSection = (state: { sections: { updSection: SectionsTypescript } }) =>
  state.sections.updSection
const getSection = (state: { sections: { section: SectionsTypescript } }) =>
  state.sections.section

// Loadings
const getLoading = (state: { sections: { loading: boolean } }) =>
  state.sections.loading
const addLoading = (state: { sections: { loadingAdd: boolean } }) =>
  state.sections.loadingAdd
const updLoading = (state: { sections: { loadingUpd: boolean } }) =>
  state.sections.loadingUpd
const getOneLoading = (state: { sections: { loadingOne: boolean } }) =>
  state.sections.loadingOne

// exports
export const getSectionsSelector = createSelector(
  getSections,
  getLoading,
  (sections: SectionsTypescript[]) => sections,
)
export const getSectionSelector = createSelector(
  getSection,
  getOneLoading,
  (section: SectionsTypescript) => section,
)
export const addSectionSelector = createSelector(
  addSection,
  addLoading,
  (addSection: SectionsTypescript) => addSection,
)
export const updSectionSelector = createSelector(
  updSection,
  updLoading,
  (updSection: SectionsTypescript) => updSection,
)

// Exports Loadings
export const getLoadingSectionsSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingSectionSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddSectionSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdSectionSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
