import { createSelector } from 'reselect'
import { EconomicGroupsTypescript } from '../../../typescript'

// Getters
const getEconomicGroups = (state: {
  economicGroups: { economicGroups: EconomicGroupsTypescript[] }
}) => state.economicGroups.economicGroups
const getEconomicGroupsToResellers = (state: {
  economicGroups: {
    economicGroupsToResellers: EconomicGroupsTypescript[]
  }
}) => state.economicGroups.economicGroupsToResellers
const addEconomicGroup = (state: {
  economicGroups: { addEconomicGroup: EconomicGroupsTypescript }
}) => state.economicGroups.addEconomicGroup
const updEconomicGroup = (state: {
  economicGroups: { updEconomicGroup: EconomicGroupsTypescript }
}) => state.economicGroups.updEconomicGroup
const getEconomicGroup = (state: {
  economicGroups: { economicGroup: EconomicGroupsTypescript }
}) => state.economicGroups.economicGroup
const getActualEconomicGroup = (state: {
  economicGroups: { actualEconomicGroup: EconomicGroupsTypescript }
}) => state.economicGroups.actualEconomicGroup

// Loadings
const getLoading = (state: { economicGroups: { loading: boolean } }) =>
  state.economicGroups.loading
const getLoadingActual = (state: {
  economicGroups: { loadingActual: boolean }
}) => state.economicGroups.loadingActual
const getLoadingToResellers = (state: {
  economicGroups: { loadingToResellers: boolean }
}) => state.economicGroups.loadingToResellers
const addLoading = (state: { economicGroups: { loadingAdd: boolean } }) =>
  state.economicGroups.loadingAdd
const updLoading = (state: { economicGroups: { loadingUpd: boolean } }) =>
  state.economicGroups.loadingUpd
const getOneLoading = (state: { economicGroups: { loadingOne: boolean } }) =>
  state.economicGroups.loadingOne

// exports
export const getEconomicGroupsSelector = createSelector(
  getEconomicGroups,
  getLoading,
  (economicGroups: EconomicGroupsTypescript[]) => economicGroups,
)
export const getEconomicGroupsToResellersSelector = createSelector(
  getEconomicGroupsToResellers,
  getLoadingToResellers,
  (economicGroupsToResellers: EconomicGroupsTypescript[]) =>
    economicGroupsToResellers,
)
export const getEconomicGroupSelector = createSelector(
  getEconomicGroup,
  getOneLoading,
  (economicGroup: EconomicGroupsTypescript) => economicGroup,
)
export const getActualEconomicGroupSelector = createSelector(
  getActualEconomicGroup,
  getLoadingActual,
  (actualEconomicGroup: EconomicGroupsTypescript) => actualEconomicGroup,
)
export const addEconomicGroupSelector = createSelector(
  addEconomicGroup,
  addLoading,
  (addEconomicGroup: EconomicGroupsTypescript) => addEconomicGroup,
)
export const updEconomicGroupSelector = createSelector(
  updEconomicGroup,
  updLoading,
  (updEconomicGroup: EconomicGroupsTypescript) => updEconomicGroup,
)

// Exports Loadings
export const getLoadingEconomicGroupsSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingEconomicGroupsToResellersSelector = createSelector(
  getLoadingToResellers,
  (loadingToResellers: boolean) => loadingToResellers,
)
export const getLoadingEconomicGroupSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingActualEconomicGroupSelector = createSelector(
  getLoadingActual,
  (loading: boolean) => loading,
)
export const getLoadingAddEconomicGroupSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdEconomicGroupSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
