/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  DashboardTemperatureTypescript,
  TemperatureTypescript,
} from '../../../typescript'
import * as types from '../../types'

export interface TemperatureState {
  loading?: boolean
  loadingLast?: boolean
  loadingLastGrid?: boolean
  loadingOne?: boolean
  serialNumber: string
  temperature: TemperatureTypescript
  dashboardTemperature: DashboardTemperatureTypescript
  temperatures: TemperatureTypescript[]
  lastTemperaturesRead: TemperatureTypescript[]
  lastTemperaturesReadGrid: TemperatureTypescript[]
  error: string | null
}

const initialState: TemperatureState = {
  loading: true,
  loadingLast: true,
  loadingLastGrid: true,
  loadingOne: false,
  serialNumber: '',
  temperature: {
    _id: '',
    deviceId: '',
    timeStamp: '',
    temperatureOne: 0,
    temperatureTwo: 0,
    temperatureThree: 0,
    inputOne: false,
    inputTwo: false,
    //inputThree: false,
  },
  dashboardTemperature: {
    device: {
      idEconomicGroup: '',
      branchNumber: 0,
      serialNumber: '',
    },
  },
  temperatures: [],
  lastTemperaturesRead: [],
  lastTemperaturesReadGrid: [],
  error: null,
}

const temperatureReducer = (
  state = initialState,
  action: {
    type: string
    payload: TemperatureTypescript[] | TemperatureTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_TEMPERATURES_REQUEST:
      return { ...state, loading: true }

    case types.ALL_TEMPERATURES_SUCCESS:
      return { ...state, temperatures: action.payload, loading: false }

    case types.ALL_TEMPERATURES_FAILURE:
      return state

    case types.ONE_DASHBOARD_TEMPERATURE_REQUEST:
      return { ...state, loadingOne: true, serialNumber: action.payload }

    case types.ONE_DASHBOARD_TEMPERATURE_SUCCESS:
      return {
        ...state,
        oneDashboardTemperature: action.payload,
        loadingOne: false,
      }

    case types.ONE_DASHBOARD_TEMPERATURE_FAILURE:
      return { ...state, loadingOne: false }

    case types.LAST_TEMPERATURES_READ_REQUEST:
      return {
        ...state,
        loadingLast: true,
        dashboardTemperature: action.payload,
      }

    case types.LAST_TEMPERATURES_READ_SUCCESS:
      return {
        ...state,
        lastTemperaturesRead: action.payload,
        loadingLast: false,
      }

    case types.LAST_TEMPERATURES_READ_FAILURE:
      return state

    case types.LAST_TEMPERATURES_READ_GRID_REQUEST:
      return {
        ...state,
        loadingLastGrid: true,
        dashboardTemperature: action.payload,
      }

    case types.LAST_TEMPERATURES_READ_GRID_SUCCESS:
      return {
        ...state,
        lastTemperaturesReadGrid: action.payload,
        loadingLastGrid: false,
      }

    case types.LAST_TEMPERATURES_READ_GRID_FAILURE:
      return state

    case types.ADD_TEMPERATURE_REQUEST:
      return { ...state, loading: true }

    case types.ADD_TEMPERATURE_SUCCESS:
      return { ...state, loading: false }

    case types.ADD_TEMPERATURE_FAILURE:
      return state

    case types.UPD_TEMPERATURE_REQUEST:
      return { ...state, loading: true }

    case types.UPD_TEMPERATURE_SUCCESS:
      return { ...state, loading: false }

    case types.UPD_TEMPERATURE_FAILURE:
      return state

    case types.DEL_TEMPERATURE_REQUEST:
      return state

    case types.DEL_TEMPERATURE_SUCCESS:
      return { ...state }

    case types.DEL_TEMPERATURE_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default temperatureReducer
