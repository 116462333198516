import { createSelector } from 'reselect'
import {
  DashboardCardsDevicesTypescript,
  DashboardCardsGroupsTypescript,
  DashboardCardsTypescript,
} from '../../../typescript'

// Getters
const getDashboardCards = (state: {
  dashboardCards: { dashboardCards: DashboardCardsTypescript }
}) => state.dashboardCards.dashboardCards
const getDashboardCardsGroups = (state: {
  dashboardCards: {
    dashboardCardsGroups: DashboardCardsGroupsTypescript[]
  }
}) => state.dashboardCards.dashboardCardsGroups
const getDashboardCardsDevices = (state: {
  dashboardCards: {
    dashboardCardsDevices: DashboardCardsDevicesTypescript[]
  }
}) => state.dashboardCards.dashboardCardsDevices
const getDashboardCardsOnlineOffline = (state: {
  dashboardCards: {
    dashboardCardsOnlineOffline: DashboardCardsTypescript[]
  }
}) => state.dashboardCards.dashboardCardsOnlineOffline
const getDashboardCardsAlertsFail = (state: {
  dashboardCards: {
    dashboardCardsAlertsFail: DashboardCardsTypescript[]
  }
}) => state.dashboardCards.dashboardCardsAlertsFail

// Loadings
const getLoadingDashboardCards = (state: {
  dashboardCards: { loading: boolean }
}) => state.dashboardCards.loading
const getLoadingDashboardCardsGroups = (state: {
  dashboardCards: { loadingGroups: boolean }
}) => state.dashboardCards.loadingGroups
const getLoadingDashboardCardsDevices = (state: {
  dashboardCards: { loadingDevices: boolean }
}) => state.dashboardCards.loadingDevices
const getLoadingDashboardCardsOnlineOffline = (state: {
  dashboardCards: { loadingOnlineOffline: boolean }
}) => state.dashboardCards.loadingOnlineOffline
const getLoadingDashboardCardsAlertsFail = (state: {
  dashboardCards: { loadingAlertsFail: boolean }
}) => state.dashboardCards.loadingAlertsFail

// exports
export const getDashboardCardsSelector = createSelector(
  getDashboardCards,
  (dashboardCards: DashboardCardsTypescript) => dashboardCards,
)
export const getDashboardCardsGroupsSelector = createSelector(
  getDashboardCardsGroups,
  (dashboardCardsGroups: DashboardCardsGroupsTypescript[]) =>
    dashboardCardsGroups,
)
export const getDashboardCardsDevicesSelector = createSelector(
  getDashboardCardsDevices,
  (dashboardCardsDevices: DashboardCardsDevicesTypescript[]) =>
    dashboardCardsDevices,
)
export const getDashboardCardsOnlineOfflineSelector = createSelector(
  getDashboardCardsOnlineOffline,
  (dashboardCardsOnlineOffline: DashboardCardsTypescript[]) =>
    dashboardCardsOnlineOffline,
)
export const getDashboardCardsAlertsFailSelector = createSelector(
  getDashboardCardsAlertsFail,
  (dashboardCardsAlertsFail: DashboardCardsTypescript[]) =>
    dashboardCardsAlertsFail,
)

// Exports Loadings
export const getLoadingDashboardCardsSelector = createSelector(
  getLoadingDashboardCards,
  (loading: boolean) => loading,
)
export const getLoadingDashboardCardsGroupsSelector = createSelector(
  getLoadingDashboardCardsGroups,
  (loading: boolean) => loading,
)
export const getLoadingDashboardCardsDevicesSelector = createSelector(
  getLoadingDashboardCardsDevices,
  (loading: boolean) => loading,
)
export const getLoadingDashboardCardsOnlineOfflineSelector = createSelector(
  getLoadingDashboardCardsOnlineOffline,
  (loadingOnlineOffline: boolean) => loadingOnlineOffline,
)
export const getLoadingDashboardCardsAlertsFailSelector = createSelector(
  getLoadingDashboardCardsAlertsFail,
  (loadingAlertsFail: boolean) => loadingAlertsFail,
)
