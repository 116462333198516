import {
  NewProfilesTreeTypescript,
  NewProfilesTypescript,
} from '../../../typescript'
import * as types from '../../types'

export interface NewProfileState {
  loading: boolean
  loadingTree: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  profile: NewProfilesTypescript
  addNewProfile: NewProfilesTypescript
  updNewProfile: NewProfilesTypescript
  newProfiles: NewProfilesTypescript[]
  newProfilesTree: NewProfilesTreeTypescript[]
  error: string | null
}

const initialState: NewProfileState = {
  loading: true,
  loadingTree: true,
  loadingOne: true,
  loadingAdd: true,
  loadingUpd: true,
  addNewProfile: {
    _id: '',
    profileName: '',
    profileNumber: 0,
    permissions: [],
    result: false,
    message: '',
    err: '',
  },
  updNewProfile: {
    _id: '',
    profileName: '',
    profileNumber: 0,
    permissions: [],
    result: false,
    message: '',
    err: '',
  },
  profile: {
    _id: '',
    profileName: '',
    profileNumber: 0,
    permissions: [],
    result: false,
    message: '',
    err: '',
  },
  newProfiles: [],
  newProfilesTree: [],
  error: null,
}

const newProfileReducer = (
  state = initialState,
  action: {
    type: string
    payload: NewProfilesTypescript[] | NewProfilesTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_NEW_PROFILES_REQUEST:
      return {
        ...state,
        loading: true,
        newProfile: action.payload,
        addNewProfile: { ...action.payload, result: false, message: '' },
        updNewProfile: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_NEW_PROFILES_SUCCESS:
      return { ...state, newProfiles: action.payload, loading: false }

    case types.ALL_NEW_PROFILES_FAILURE:
      return state

    case types.ALL_NEW_PROFILES_TREE_REQUEST:
      return {
        ...state,
        loadingTree: true,
        newProfile: action.payload,
      }

    case types.ALL_NEW_PROFILES_TREE_SUCCESS:
      return { ...state, newProfilesTree: action.payload, loadingTree: false }

    case types.ALL_NEW_PROFILES_TREE_FAILURE:
      return { ...state, loadingTree: false }

    case types.ADD_NEW_PROFILE_REQUEST:
      return { ...state, addNewProfile: action.payload, loadingAdd: true }

    case types.ADD_NEW_PROFILE_SUCCESS:
      return { ...state, addNewProfile: action.payload, loadingAdd: false }

    case types.ADD_NEW_PROFILE_FAILURE:
      return state

    case types.UPD_NEW_PROFILE_REQUEST:
      return { ...state, updNewProfile: action.payload, loadingUpd: true }

    case types.UPD_NEW_PROFILE_SUCCESS:
      return { ...state, updNewProfile: action.payload, loadingUpd: false }

    case types.UPD_NEW_PROFILE_FAILURE:
      return { ...state, updNewProfile: action.payload }

    case types.DEL_NEW_PROFILE_REQUEST:
      return state

    case types.DEL_NEW_PROFILE_SUCCESS:
      return { ...state }

    case types.DEL_NEW_PROFILE_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default newProfileReducer
