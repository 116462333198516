/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PositionsTypescript } from '../../../typescript'
import * as types from '../../types'

export interface PositionState {
  loading: boolean
  position: PositionsTypescript
  positions: PositionsTypescript[]
  error: string | null
}

const initialState: PositionState = {
  loading: true,
  position: {
    _id: '',
    deviceId: '',
    activeButton: '',
    latitude: 0,
    longitude: 0,
    statusIO: '',
    timeStamp: new Date(),
  },
  positions: [],
  error: null,
}

const positionReducer = (
  state = initialState,
  action: {
    type: string
    payload: PositionsTypescript[] | PositionsTypescript
  },
) => {
  switch (action.type) {
    case types.ONE_POSITION_REQUEST:
      return { ...state, loading: true }

    case types.ONE_POSITION_SUCCESS:
      return { ...state, data: action.payload }

    case types.ONE_POSITION_FAILURE:
      return state

    case types.ALL_POSITIONS_REQUEST:
      return { ...state, loading: true }

    case types.ALL_POSITIONS_SUCCESS:
      return { ...state, positions: action.payload, loading: false }

    case types.ALL_POSITIONS_FAILURE:
      return state

    case types.ADD_POSITION_REQUEST:
      return { ...state, loading: true }

    case types.ADD_POSITION_SUCCESS:
      return { ...state, loading: true }

    case types.ADD_POSITION_FAILURE:
      return state

    case types.UPD_POSITION_REQUEST:
      return state

    case types.UPD_POSITION_SUCCESS:
      return { ...state }

    case types.UPD_POSITION_FAILURE:
      return state

    case types.DEL_POSITION_REQUEST:
      return state

    case types.DEL_POSITION_SUCCESS:
      return { ...state }

    case types.DEL_POSITION_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default positionReducer
