import { createSelector } from 'reselect'
import { DevicesTypescript } from '../../../typescript'

// Getters
const getDevices = (state: { devices: { devices: DevicesTypescript[] } }) =>
  state.devices.devices
const getDashBoardCardDevices = (state: {
  devices: { dashBoardCardDevices: DevicesTypescript[] }
}) => state.devices.dashBoardCardDevices
const addDevice = (state: { devices: { addDevice: DevicesTypescript } }) =>
  state.devices.addDevice
const updDevice = (state: { devices: { updDevice: DevicesTypescript } }) =>
  state.devices.updDevice
const updGroupDevice = (state: {
  devices: { updGroupDevice: DevicesTypescript }
}) => state.devices.updGroupDevice
const getDevice = (state: { devices: { device: DevicesTypescript } }) =>
  state.devices.device

// Loadings
const getLoading = (state: { devices: { loading: boolean } }) =>
  state.devices.loading

const loadingDashBoardCardDevices = (state: {
  devices: { loadingDashBoardCardDevices: boolean }
}) => state.devices.loadingDashBoardCardDevices

const addLoading = (state: { devices: { loadingAdd: boolean } }) =>
  state.devices.loadingAdd

const updLoading = (state: { devices: { loadingUpd: boolean } }) =>
  state.devices.loadingUpd

const updGroupLoading = (state: { devices: { loadingUpdGroup: boolean } }) =>
  state.devices.loadingUpdGroup

const getOneLoading = (state: { devices: { loadingOne: boolean } }) =>
  state.devices.loadingOne

// exports
export const getDevicesSelector = createSelector(
  getDevices,
  getLoading,
  (devices: DevicesTypescript[]) => devices,
)
export const getDashBoardCardDevicesSelector = createSelector(
  getDashBoardCardDevices,
  loadingDashBoardCardDevices,
  (dashBoardCardDevices: DevicesTypescript[]) => dashBoardCardDevices,
)
export const getDeviceSelector = createSelector(
  getDevice,
  getOneLoading,
  (device: DevicesTypescript) => device,
)
export const addDeviceSelector = createSelector(
  addDevice,
  addLoading,
  (addDevice: DevicesTypescript) => addDevice,
)
export const updDeviceSelector = createSelector(
  updDevice,
  updLoading,
  (updDevice: DevicesTypescript) => updDevice,
)
export const updGroupDeviceSelector = createSelector(
  updGroupDevice,
  updGroupLoading,
  (updGroupDevice: DevicesTypescript) => updGroupDevice,
)

// Exports Loadings
export const getLoadingDevicesSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getloadingDashBoardCardDevicesSelector = createSelector(
  loadingDashBoardCardDevices,
  (loadingDashBoardCardDevices: boolean) => loadingDashBoardCardDevices,
)
export const getLoadingDeviceSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddDeviceSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdDeviceSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
export const getLoadingUpdGroupDeviceSelector = createSelector(
  updGroupLoading,
  (loadingUpdGroup: boolean) => loadingUpdGroup,
)
