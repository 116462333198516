import { createSelector } from 'reselect'
import { BranchesTypescript } from '../../../typescript'

// Getters
const getBranches = (state: { branches: { branches: BranchesTypescript[] } }) =>
  state.branches.branches
const getBranchesByGroup = (state: {
  branches: { branchesByGroup: BranchesTypescript[] }
}) => state.branches.branchesByGroup
const addBranch = (state: { branches: { addBranch: BranchesTypescript } }) =>
  state.branches.addBranch
const updBranch = (state: { branches: { updBranch: BranchesTypescript } }) =>
  state.branches.updBranch
const getBranch = (state: { branches: { branch: BranchesTypescript } }) =>
  state.branches.branch

// Loadings
const getLoading = (state: { branches: { loading: boolean } }) =>
  state.branches.loading
const getLoadingByGroup = (state: { branches: { loadingByGroup: boolean } }) =>
  state.branches.loadingByGroup
const addLoading = (state: { branches: { loadingAdd: boolean } }) =>
  state.branches.loadingAdd
const updLoading = (state: { branches: { loadingUpd: boolean } }) =>
  state.branches.loadingUpd
const getOneLoading = (state: { branches: { loadingOne: boolean } }) =>
  state.branches.loadingOne

// exports
export const getBranchesSelector = createSelector(
  getBranches,
  getLoading,
  (branches: BranchesTypescript[]) => branches,
)
export const getBranchesByGroupSelector = createSelector(
  getBranchesByGroup,
  getLoadingByGroup,
  (branchesByGroup: BranchesTypescript[]) => branchesByGroup,
)
export const getBranchSelector = createSelector(
  getBranch,
  getOneLoading,
  (branch: BranchesTypescript) => branch,
)
export const addBranchSelector = createSelector(
  addBranch,
  addLoading,
  (addBranch: BranchesTypescript) => addBranch,
)
export const updBranchSelector = createSelector(
  updBranch,
  updLoading,
  (updBranch: BranchesTypescript) => updBranch,
)

// Exports Loadings
export const getLoadingBranchesSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingBranchesByGroupSelector = createSelector(
  getLoadingByGroup,
  (loadingByGroup: boolean) => loadingByGroup,
)
export const getLoadingBranchSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddBranchSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdBranchSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
