import { UsersTypescript } from '../../../typescript'
import * as types from '../../types'

export function oneUserRequest(user: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.ONE_USER_REQUEST,
    payload: user,
  }
}
export function oneUserSuccess(data: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.ONE_USER_SUCCESS,
    payload: data,
  }
}
export function oneUserFailure(): { type: string } {
  return {
    type: types.ONE_USER_FAILURE,
  }
}

export function allUsersRequest(param: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.ALL_USERS_REQUEST,
    payload: param,
  }
}
export function allUsersSuccess(data: UsersTypescript[]): {
  type: string
  payload: UsersTypescript[]
} {
  return {
    type: types.ALL_USERS_SUCCESS,
    payload: data,
  }
}
export function allUsersFailure(): { type: string } {
  return {
    type: types.ALL_USERS_FAILURE,
  }
}

export function addUserRequest(param: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.ADD_USER_REQUEST,
    payload: param,
  }
}
export function addUserSuccess(data: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.ADD_USER_SUCCESS,
    payload: data,
  }
}
export function addUserFailure(): { type: string } {
  return {
    type: types.ADD_USER_FAILURE,
  }
}

export function updUserRequest(param: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.UPD_USER_REQUEST,
    payload: param,
  }
}
export function updUserSuccess(data: UsersTypescript[]): {
  type: string
  payload: UsersTypescript[]
} {
  return {
    type: types.UPD_USER_SUCCESS,
    payload: data,
  }
}
export function updUserFailure(data: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.UPD_USER_FAILURE,
    payload: data,
  }
}

export function updateUserGroupRequest(param: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.UPD_USER_GROUP_REQUEST,
    payload: param,
  }
}
export function updateUserGroupSuccess(data: UsersTypescript[]): {
  type: string
  payload: UsersTypescript[]
} {
  return {
    type: types.UPD_USER_GROUP_SUCCESS,
    payload: data,
  }
}
export function updateUserGroupFailure(data: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.UPD_USER_GROUP_FAILURE,
    payload: data,
  }
}
