import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as Actions from '../../../actions/reports/temperatureDetails'
import * as types from '../../../types'
import api from '../../../../api'

const allReportTemperatureDetailsRequest = async (find: any) => {
  return new Promise((resolve, reject) => {
    const query = `
    query{
      allTemperaturesReport(find:{         
        limit: 1000
        deviceId: "${find.deviceId.toUpperCase()}"
        startTimeStamp: "${find.startDate}"
        stopTimeStamp: "${find.stopDate ? find.stopDate : find.startDate}"
      }     
      ){
        device{
            serialNumber
            description
            temperatureNameOne
            temperatureNameTwo
            temperatureNameThree
            inputNameOne
            inputNameTwo
        } 
        temperatures {
        timeStamp
        deviceId
        temperatureOne
        minimumAlertOne
        maximumAlertOne
        minimumRangeOne
        maximumRangeOne
        temperatureTwo
        minimumAlertTwo
        maximumAlertTwo
        minimumRangeTwo
        maximumRangeTwo
        temperatureThree
        minimumAlertThree
        maximumAlertThree
        minimumRangeThree
        maximumRangeThree
        inputOne
        inputTwo
        }
        result
        message
      }
  }
  `
    return api({
      method: 'post',
      data: {
        query,
      },
    })
      .then(({ data }) => {
        const { allTemperaturesReport } = data.data || {}
        resolve(
          allTemperaturesReport.device
            ? allTemperaturesReport
            : { ...allTemperaturesReport, device: {}, temperatures: [] },
          //Feito tratamento para não deixar o device null pois o
          // Sistema não estava aceitando.
        )
      })
      .catch(reject)
  })
}

const allReportTemperatureDetailsByDateRequest = async (find: any) => {
  return new Promise((resolve, reject) => {
    const query = `
    query{
      sintTempsReportByDate(find:{         
        limit: 1000
        deviceId: "${find.deviceId.toUpperCase()}"
        startTimeStamp: "${find.startDate}"
        stopTimeStamp: "${find.stopDate}"
  
      }     
      ){
         device{
             serialNumber
             description
             idEconomicGroup
             branchNumber
             temperatureNameOne
             temperatureNameTwo
             temperatureNameThree
             inputNameOne
             inputNameTwo             
         } 
         resumeTemperatures{    
         timeStamp
         deviceId
         records
         openings
         minimumOne
         averageOne
         maximumOne
         minimumTwo
         maximumTwo
         averageTwo
         minimumThree
         maximumThree
         averageThree
         
         }    
  
         result
         message  
  
  
      }
  }
  `

    return api({
      method: 'post',
      data: {
        query,
      },
    })
      .then(({ data }) => {
        const { sintTempsReportByDate } = data.data || {}

        resolve(
          sintTempsReportByDate.device
            ? sintTempsReportByDate
            : { ...sintTempsReportByDate, device: {}, temperatures: [] },
          //Feito tratamento para não deixar o device null pois o
          // Sistema não estava aceitando.
        )
      })
      .catch(reject)
  })
}

function* allReportTemperatureDetailsByDate(action) {
  const data = yield call(
    allReportTemperatureDetailsByDateRequest,
    action.payload,
  )
  if (!data.result) {
    yield put(Actions.allReportTemperatureDetailsByDateFailure(data))
  } else {
    yield put(Actions.allReportTemperatureDetailsByDateSuccess(data))
  }
}
function* allReportTemperatureDetails(action) {
  const data = yield call(allReportTemperatureDetailsRequest, action.payload)
  if (!data.result) {
    yield put(Actions.allReportTemperatureDetailsFailure(data))
  } else {
    yield put(Actions.allReportTemperatureDetailsSuccess(data))
  }
}

export default all([
  takeLatest(
    types.ALL_REPORT_TEMPERATURE_DETAILS_REQUEST,
    allReportTemperatureDetails,
  ),
  takeLatest(
    types.SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_REQUEST,
    allReportTemperatureDetailsByDate,
  ),
])
