import {
  ReportTemperatureDetailsByDateTypescript,
  ReportTemperatureDetailsTypescript,
} from '../../../../typescript/reports/temperatureDetails'

import * as types from '../../../types'

export function allReportTemperatureDetailsRequest(find: any): {
  type: string
  payload: any
} {
  return {
    type: types.ALL_REPORT_TEMPERATURE_DETAILS_REQUEST,
    payload: find,
  }
}
export function allReportTemperatureDetailsSuccess(
  data: ReportTemperatureDetailsTypescript,
): {
  type: string
  payload: ReportTemperatureDetailsTypescript
} {
  return {
    type: types.ALL_REPORT_TEMPERATURE_DETAILS_SUCCESS,
    payload: data,
  }
}
export function allReportTemperatureDetailsFailure(
  data: ReportTemperatureDetailsTypescript,
): { type: string; payload: ReportTemperatureDetailsTypescript } {
  return {
    type: types.ALL_REPORT_TEMPERATURE_DETAILS_FAILURE,
    payload: data,
  }
}

// By Date
export function allReportTemperatureDetailsByDateRequest(find: any): {
  type: string
  payload: any
} {
  return {
    type: types.SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_REQUEST,
    payload: find,
  }
}
export function allReportTemperatureDetailsByDateSuccess(
  data: ReportTemperatureDetailsByDateTypescript,
): {
  type: string
  payload: ReportTemperatureDetailsByDateTypescript
} {
  return {
    type: types.SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_SUCCESS,
    payload: data,
  }
}
export function allReportTemperatureDetailsByDateFailure(
  data: ReportTemperatureDetailsByDateTypescript,
): { type: string; payload: ReportTemperatureDetailsByDateTypescript } {
  return {
    type: types.SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_FAILURE,
    payload: data,
  }
}
