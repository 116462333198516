import { createSelector } from 'reselect'
import { LoginTypescript } from '../../../typescript'

// Getters
const getrecoveryPassword = (state: {
  recoveryPassword: { recoveryPassword: LoginTypescript }
}) => state.recoveryPassword.recoveryPassword

// Loadings
const getLoading = (state: { recoveryPassword: { loading: boolean } }) =>
  state.recoveryPassword.loading

// exports
export const getRecoveryPasswordSelector = createSelector(
  getrecoveryPassword,
  getLoading,
  (recoveryPassword: LoginTypescript) => recoveryPassword,
)

// Exports Loadings
export const getLoadingRecoveryPasswordSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
