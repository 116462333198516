import { createSelector } from 'reselect'
import {
  ReportTemperatureDetailsByDateTypescript,
  ReportTemperatureDetailsTypescript,
} from '../../../../typescript/reports/temperatureDetails'

// Getters
const getReportTemperatureDetails = (state: {
  reportTemperatureDetails: { data: ReportTemperatureDetailsTypescript }
}) => state.reportTemperatureDetails.data
const getReportTemperatureDetailsByDate = (state: {
  reportTemperatureDetails: {
    dataByDate: ReportTemperatureDetailsByDateTypescript
  }
}) => state.reportTemperatureDetails.dataByDate

// Loadings
const getLoading = (state: {
  reportTemperatureDetails: { loading: boolean }
}) => state.reportTemperatureDetails.loading
const getLoadingByDate = (state: {
  reportTemperatureDetails: { loadingByDate: boolean }
}) => state.reportTemperatureDetails.loadingByDate

// exports
export const getReportTemperatureDetailsSelector = createSelector(
  getReportTemperatureDetails,
  getLoading,
  (data: ReportTemperatureDetailsTypescript) => data,
)
export const getReportTemperatureDetailsByDateSelector = createSelector(
  getReportTemperatureDetailsByDate,
  getLoadingByDate,
  (dataByDate: ReportTemperatureDetailsTypescript) => dataByDate,
)

// Exports Loadings
export const getLoadingReportTemperatureDetailsSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingReportTemperatureDetailsByDateSelector = createSelector(
  getLoadingByDate,
  (loadingByDate: boolean) => loadingByDate,
)
