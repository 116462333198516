import axios, { AxiosRequestConfig } from 'axios'

const api = axios.create({
  // baseURL: 'http://iot.appwb.com.br/backend/graphql'
  baseURL: 'https://www.globaliot.com.br/backend',
  // baseURL: 'http://www.globaliot.com.br:65005/backend/graphql', //Homologação
  //baseURL: 'http://localhost:3001/',
})

api.interceptors.request.use(async (_config: AxiosRequestConfig) => {
  const token = await localStorage.getItem('global-iot/token')
  if (token) {
    _config.headers.Authorization = `Bearen ${token}`
    _config.headers['X-Timezone-Offset'] = getTimezoneOffset()
  }
  return _config
})

const getTimezoneOffset = () => {
  return String(new Date().getTimezoneOffset())
}

export default api
