import {
  DashboardCardsDevicesTypescript,
  DashboardCardsGroupsTypescript,
  DashboardCardsTypescript,
} from '../../../typescript'
import * as types from '../../types'

export function DashboardCardsRequest(data: DashboardCardsTypescript): {
  type: string
  payload: DashboardCardsTypescript
} {
  return {
    type: types.DASHBOARD_CARDS_REQUEST,
    payload: data,
  }
}
export function DashboardCardsSuccess(data: DashboardCardsTypescript): {
  type: string
  payload: DashboardCardsTypescript
} {
  return {
    type: types.DASHBOARD_CARDS_SUCCESS,
    payload: data,
  }
}
export function DashboardCardsFailure(): { type: string } {
  return {
    type: types.DASHBOARD_CARDS_FAILURE,
  }
}

export function DashboardCardsGroupsRequest(data: DashboardCardsTypescript): {
  type: string
  payload: DashboardCardsTypescript
} {
  return {
    type: types.DASHBOARD_CARDS_GROUPS_REQUEST,
    payload: data,
  }
}
export function DashboardCardsGroupsSuccess(
  data: DashboardCardsGroupsTypescript[],
): {
  type: string
  payload: DashboardCardsGroupsTypescript[]
} {
  return {
    type: types.DASHBOARD_CARDS_GROUPS_SUCCESS,
    payload: data,
  }
}
export function DashboardCardsGroupsFailure(): { type: string } {
  return {
    type: types.DASHBOARD_CARDS_GROUPS_FAILURE,
  }
}

export function DashboardCardsDevicesRequest(data: DashboardCardsTypescript): {
  type: string
  payload: DashboardCardsTypescript
} {
  return {
    type: types.DASHBOARD_CARDS_DEVICES_REQUEST,
    payload: data,
  }
}
export function DashboardCardsDevicesSuccess(
  data: DashboardCardsDevicesTypescript[],
): {
  type: string
  payload: DashboardCardsDevicesTypescript[]
} {
  return {
    type: types.DASHBOARD_CARDS_DEVICES_SUCCESS,
    payload: data,
  }
}
export function DashboardCardsDevicesFailure(): { type: string } {
  return {
    type: types.DASHBOARD_CARDS_DEVICES_FAILURE,
  }
}

export function DashboardCardsOnlineOfflineRequest(
  data: DashboardCardsTypescript,
): {
  type: string
  payload: DashboardCardsTypescript
} {
  return {
    type: types.DASHBOARD_CARDS_ONLINE_OFFLINE_REQUEST,
    payload: data,
  }
}
export function DashboardCardsOnlineOfflineSuccess(
  data: DashboardCardsTypescript[],
): {
  type: string
  payload: DashboardCardsTypescript[]
} {
  return {
    type: types.DASHBOARD_CARDS_ONLINE_OFFLINE_SUCCESS,
    payload: data,
  }
}
export function DashboardCardsOnlineOfflineFailure(): { type: string } {
  return {
    type: types.DASHBOARD_CARDS_ONLINE_OFFLINE_FAILURE,
  }
}

export function DashboardCardsAlertsFailRequest(
  data: DashboardCardsTypescript,
): {
  type: string
  payload: DashboardCardsTypescript
} {
  return {
    type: types.DASHBOARD_CARDS_ALERTS_FAIL_REQUEST,
    payload: data,
  }
}
export function DashboardCardsAlertsFailSuccess(
  data: DashboardCardsTypescript,
): {
  type: string
  payload: DashboardCardsTypescript
} {
  return {
    type: types.DASHBOARD_CARDS_ALERTS_FAIL_SUCCESS,
    payload: data,
  }
}
export function DashboardCardsAlertsFailFailure(): { type: string } {
  return {
    type: types.DASHBOARD_CARDS_ALERTS_FAIL_FAILURE,
  }
}
