import {
  DashboardTemperatureTypescript,
  LastTemperatureReadTypescript,
  TemperatureTypescript,
} from '../../../typescript'
import * as types from '../../types'

const temperature = {}

export function oneTemperatureRequest(): {
  type: string
  payload
} {
  return {
    type: types.ONE_TEMPERATURE_REQUEST,
    payload: temperature,
  }
}
export function oneTemperatureSuccess(): { type: string } {
  return {
    type: types.ONE_TEMPERATURE_SUCCESS,
  }
}
export function oneTemperatureFailure(): { type: string } {
  return {
    type: types.ONE_TEMPERATURE_FAILURE,
  }
}

export function oneDashboardTemperatureRequest(serialNumber): {
  type: string
  payload: TemperatureTypescript
} {
  return {
    type: types.ONE_DASHBOARD_TEMPERATURE_REQUEST,
    payload: serialNumber,
  }
}
export function oneDashboardTemperatureSuccess(
  data: DashboardTemperatureTypescript[],
): {
  type: string
  payload: DashboardTemperatureTypescript[]
} {
  return {
    type: types.ONE_DASHBOARD_TEMPERATURE_SUCCESS,
    payload: data,
  }
}
export function oneDashboardTemperatureFailure(): { type: string } {
  return {
    type: types.ONE_DASHBOARD_TEMPERATURE_FAILURE,
  }
}

export function allTemperaturesRequest(): {
  type: string
} {
  return {
    type: types.ALL_TEMPERATURES_REQUEST,
  }
}

export function allTemperaturesSuccess(
  data: DashboardTemperatureTypescript[],
): {
  type: string
  payload: DashboardTemperatureTypescript[]
} {
  return {
    type: types.ALL_TEMPERATURES_SUCCESS,
    payload: data,
  }
}
export function allTemperaturesFailure(): { type: string } {
  return {
    type: types.ALL_TEMPERATURES_FAILURE,
  }
}

export function lastTemperaturesReadRequest(dashboardTemperature): {
  type: string
  payload: DashboardTemperatureTypescript
} {
  return {
    type: types.LAST_TEMPERATURES_READ_REQUEST,
    payload: dashboardTemperature,
  }
}
export function lastTemperaturesReadSuccess(
  data: LastTemperatureReadTypescript[],
): {
  type: string
  payload: LastTemperatureReadTypescript[]
} {
  return {
    type: types.LAST_TEMPERATURES_READ_SUCCESS,
    payload: data,
  }
}
export function lastTemperaturesReadFailure(): { type: string } {
  return {
    type: types.LAST_TEMPERATURES_READ_FAILURE,
  }
}

export function lastTemperaturesReadGridRequest(params): {
  type: string
  payload: LastTemperatureReadTypescript
} {
  return {
    type: types.LAST_TEMPERATURES_READ_GRID_REQUEST,
    payload: params,
  }
}
export function lastTemperaturesReadGridSuccess(
  data: LastTemperatureReadTypescript[],
): {
  type: string
  payload: LastTemperatureReadTypescript[]
} {
  return {
    type: types.LAST_TEMPERATURES_READ_GRID_SUCCESS,
    payload: data,
  }
}
export function lastTemperaturesReadGridFailure(): { type: string } {
  return {
    type: types.LAST_TEMPERATURES_READ_GRID_FAILURE,
  }
}

export function addTemperatureRequest(data: TemperatureTypescript): {
  type: string
  payload: TemperatureTypescript
} {
  return {
    type: types.ADD_TEMPERATURE_REQUEST,
    payload: data,
  }
}
export function addTemperatureSuccess(): {
  type: string
} {
  return {
    type: types.ADD_TEMPERATURE_SUCCESS,
  }
}
export function addTemperaturesFailure(): { type: string } {
  return {
    type: types.ADD_TEMPERATURE_FAILURE,
  }
}
