import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { MenusTypescript, NewProfilesTypescript } from '../../../typescript'
import * as NewProfilesActions from '../../actions/newProfiles'
import * as types from '../../types'

let allNewProfilesApi: NewProfilesTypescript[]
const allNewProfilesRequisicao = async (newProfile: NewProfilesTypescript) => {
  const query = `
  query {
    allNewProfiles(find: {
      ${newProfile._id ? '_id: "' + newProfile._id + '"' : ''}
      ${
        newProfile.profileName
          ? 'profileName: "' + newProfile.profileName + '"'
          : ''
      }
      ${
        newProfile.profileNumber
          ? 'profileNumber: ' + newProfile.profileNumber
          : ''
      }
    }) {
      _id
      profileNumber
      profileName
      permissions {
        menuId
        permission
      }
      result
      message
      err
    }
  }
    `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allNewProfilesApi = data.data.allNewProfiles
    return true
  })
}

let allNewProfilesTreeApi: MenusTypescript[]
const allNewProfilesTreeRequisicao = async (newProfile: {
  profileNumber: string
}) => {
  const query = `
  query allMenusTree{
    allMenusTree(find:{
        profileNumber: "${newProfile.profileNumber}"        
    })
    {
      menuId
      description
      rootMenu
      position
      status
      path
      permission
      icon
      
      }  
    
}
    `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allNewProfilesTreeApi = data.data.allMenusTree
    return true
  })
}

let addNewProfilesApi: NewProfilesTypescript
const addNewProfileRequisicao = async (newProfile: NewProfilesTypescript) => {
  const query = `
  mutation {
    createNewProfiles(input: {
      profileName: "${newProfile.profileName}"
      permissions: [${newProfile.permissions?.map((permission) => {
        return `{
          menuId: ${permission.menuId},
          permission: ${permission.permission},
        }`
      })}]
    }) {
      result
      message
      err
    }
  }
  `

  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      addNewProfilesApi = data.data.createNewProfiles

      return true
    })
    .catch((e) => e)
}
let updNewProfileApi
const updNewProfileRequisicao = async (newProfile: NewProfilesTypescript) => {
  const query = `
        mutation {
          updateNewProfiles(update:{
            _id: "${newProfile._id}",
            profileName: "${newProfile.profileName}"
            profileNumber: ${newProfile.profileNumber}
            permissions: [${newProfile.permissions?.map((permission) => {
              return `{
                menuId: ${permission.menuId},
                permission: ${permission.permission},
              }`
            })}]
          })
            {
            result
            message
            err
          }
        }
      `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updNewProfileApi = data.data.updateNewProfiles

    return true
  })
}

function* allNewProfiles(action) {
  try {
    yield call(allNewProfilesRequisicao, action.payload)
    yield put(NewProfilesActions.allNewProfilesSuccess(allNewProfilesApi))
  } catch {
    yield put(NewProfilesActions.allNewProfilesFailure())
  }
}
function* allNewProfilesTree(action) {
  try {
    yield call(allNewProfilesTreeRequisicao, action.payload)
    yield put(
      NewProfilesActions.allNewProfilesTreeSuccess(allNewProfilesTreeApi),
    )
  } catch {
    yield put(NewProfilesActions.allNewProfilesTreeFailure())
  }
}

function* addNewProfile(action) {
  try {
    yield call(addNewProfileRequisicao, action.payload)
    yield put(NewProfilesActions.addNewProfileSuccess(addNewProfilesApi))
  } catch {
    yield put(NewProfilesActions.addNewProfileFailure())
  }
}

function* updNewProfile(action) {
  try {
    yield call(updNewProfileRequisicao, action.payload)
    yield put(NewProfilesActions.updNewProfileSuccess(updNewProfileApi))
  } catch {
    yield put(NewProfilesActions.updNewProfileFailure(updNewProfileApi))
  }
}
export default all([
  takeLatest(types.ALL_NEW_PROFILES_REQUEST, allNewProfiles),
  takeLatest(types.ALL_NEW_PROFILES_TREE_REQUEST, allNewProfilesTree),
  takeLatest(types.ADD_NEW_PROFILE_REQUEST, addNewProfile),
  takeLatest(types.UPD_NEW_PROFILE_REQUEST, updNewProfile),
])
