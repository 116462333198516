import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { ProfilesTypescript } from '../../../typescript'
import * as ProfilesActions from '../../actions/profiles'
import * as types from '../../types'

let allProfilesApi: ProfilesTypescript[]
const allProfilesRequisicao = async (profile: ProfilesTypescript) => {
  const query = `
  query {
    allProfiles(find: {
      ${profile.profileName ? 'profileName: "' + profile.profileName + '"' : ''}
    }) {
      _id
      profileNumber
      profileName
      usersRegistration
      economicGroupsRegistration
      branchesRegistration
      devicesRegistration
      commandsRegistration
      devicesTypeRegistration
      resellersRegistration
      hide
      result
      message
      err
    }
  }
    `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allProfilesApi = data.data.allProfiles
    return true
  })
}

let addProfilesApi: ProfilesTypescript
const addProfileRequisicao = async (profile: ProfilesTypescript) => {
  const query = `
  mutation {
    createProfiles(input: {
      profileName: "${profile.profileName}"
      usersRegistration: ${profile.usersRegistration}
      economicGroupsRegistration: ${profile.economicGroupsRegistration}
      branchesRegistration: ${profile.branchesRegistration}
      devicesRegistration: ${
        profile.devicesRegistration === undefined
          ? 0
          : profile.devicesRegistration
      }
      commandsRegistration: ${
        profile.commandsRegistration === undefined
          ? 0
          : profile.commandsRegistration
      }
      devicesTypeRegistration: ${profile.devicesTypeRegistration}
      resellersRegistration: ${profile.resellersRegistration}
      hide: ${profile.hide}      
    }) {
      result
      message
      err
    }
  }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      addProfilesApi = data.data.createProfiles

      return true
    })
    .catch((e) => e)
}
let updProfileApi
const updProfileRequisicao = async (profile: ProfilesTypescript) => {
  const query = `
        mutation {
          updateProfiles(update:{
            _id: "${profile._id}",
            profileName: "${profile.profileName}",
            usersRegistration: ${profile.usersRegistration},
            economicGroupsRegistration: ${profile.economicGroupsRegistration},
            branchesRegistration: ${profile.branchesRegistration},
            devicesRegistration: ${profile.devicesRegistration},
            commandsRegistration: ${profile.commandsRegistration},
            devicesTypeRegistration: ${profile.devicesTypeRegistration},
            resellersRegistration: ${profile.resellersRegistration},
            hide: ${profile.hide},
          })
            {
            result
            message
            err
          }
        }
      `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updProfileApi = data.data.updateProfiles

    return true
  })
}

function* allProfiles(action) {
  try {
    yield call(allProfilesRequisicao, action.payload)
    yield put(ProfilesActions.allProfilesSuccess(allProfilesApi))
  } catch {
    yield put(ProfilesActions.allProfilesFailure())
  }
}

function* addProfile(action) {
  try {
    yield call(addProfileRequisicao, action.payload)
    yield put(ProfilesActions.addProfileSuccess(addProfilesApi))
  } catch {
    yield put(ProfilesActions.addProfileFailure())
  }
}

function* updProfile(action) {
  try {
    yield call(updProfileRequisicao, action.payload)
    yield put(ProfilesActions.updProfileSuccess(updProfileApi))
  } catch {
    yield put(ProfilesActions.updProfileFailure(updProfileApi))
  }
}
export default all([
  takeLatest(types.ALL_PROFILES_REQUEST, allProfiles),
  takeLatest(types.ADD_PROFILE_REQUEST, addProfile),
  takeLatest(types.UPD_PROFILE_REQUEST, updProfile),
])
