import { ResellersTypescript } from '../../../typescript'
import * as types from '../../types'

export interface ResellerState {
  loading: boolean
  allowedLoading: boolean
  loadingFiltered: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  reseller: ResellersTypescript
  addReseller: ResellersTypescript
  updReseller: ResellersTypescript
  resellers: ResellersTypescript[]
  allowedResellers: ResellersTypescript[]
  resellersFiltered: ResellersTypescript[]
  error: string | null
}

const initialState: ResellerState = {
  loading: true,
  allowedLoading: true,
  loadingFiltered: true,
  loadingOne: true,
  loadingAdd: true,
  loadingUpd: true,
  addReseller: {
    _id: '',
    resaleNumber: undefined,
    resaleName: '',
    identificationDocument: '',
    address: '',
    number: undefined,
    district: '',
    city: '',
    complement: '',
    zipCode: '',
    state: '',
    country: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    observations: '',
    associatedGroups: '',
    registrationDate: undefined,
    updateDate: undefined,
    registrationUser: '',
    updateUser: '',
    result: false,
    message: undefined,
    err: undefined,
  },
  updReseller: {
    _id: '',
    resaleNumber: undefined,
    resaleName: '',
    identificationDocument: '',
    address: '',
    number: undefined,
    district: '',
    city: '',
    complement: '',
    zipCode: '',
    state: '',
    country: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    observations: '',
    associatedGroups: '',
    registrationDate: undefined,
    updateDate: undefined,
    registrationUser: '',
    updateUser: '',
    result: false,
    message: undefined,
    err: undefined,
  },
  reseller: {
    _id: '',
    resaleNumber: undefined,
    resaleName: '',
    identificationDocument: '',
    address: '',
    number: undefined,
    district: '',
    city: '',
    complement: '',
    zipCode: '',
    state: '',
    country: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    observations: '',
    associatedGroups: '',
    registrationDate: undefined,
    updateDate: undefined,
    registrationUser: '',
    updateUser: '',
    result: false,
    message: undefined,
    err: undefined,
  },
  resellers: [],
  allowedResellers: [],
  resellersFiltered: [],
  error: null,
}

const resellerReducer = (
  state = initialState,
  action: {
    type: string
    payload: ResellersTypescript[] | ResellersTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_RESELLERS_REQUEST:
      return {
        ...state,
        loading: true,
        reseller: action.payload,
        addReseller: { ...action.payload, result: false, message: '' },
        updReseller: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_RESELLERS_SUCCESS:
      return { ...state, resellers: action.payload, loading: false }

    case types.ALL_RESELLERS_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case types.ALLOWED_RESELLERS_REQUEST:
      return {
        ...state,
        allowedLoading: true,
      }

    case types.ALLOWED_RESELLERS_SUCCESS:
      return {
        ...state,
        allowedResellers: action.payload,
        allowedLoading: false,
      }

    case types.ALLOWED_RESELLERS_FAILURE:
      return {
        ...state,
        allowedLoading: false,
      }

    case types.ADD_RESELLER_REQUEST:
      return { ...state, addReseller: action.payload, loadingAdd: true }

    case types.ADD_RESELLER_SUCCESS:
      return { ...state, addReseller: action.payload, loadingAdd: false }

    case types.ADD_RESELLER_FAILURE:
      return state

    case types.UPD_RESELLER_REQUEST:
      return { ...state, updReseller: action.payload, loadingUpd: true }

    case types.UPD_RESELLER_SUCCESS:
      return { ...state, updReseller: action.payload, loadingUpd: false }

    case types.UPD_RESELLER_FAILURE:
      return { ...state, updReseller: action.payload }

    case types.DEL_RESELLER_REQUEST:
      return state

    case types.DEL_RESELLER_SUCCESS:
      return { ...state }

    case types.DEL_RESELLER_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default resellerReducer
