import { DepartmentsTypescript } from '../../../typescript'
import * as types from '../../types'

export function oneDepartmentRequest(user: DepartmentsTypescript): {
  type: string
  payload: DepartmentsTypescript
} {
  return {
    type: types.ONE_DEPARTMENT_REQUEST,
    payload: user,
  }
}
export function oneDepartmentSuccess(data: DepartmentsTypescript): {
  type: string
  payload: DepartmentsTypescript
} {
  return {
    type: types.ONE_DEPARTMENT_SUCCESS,
    payload: data,
  }
}
export function oneDepartmentFailure(): { type: string } {
  return {
    type: types.ONE_DEPARTMENT_FAILURE,
  }
}

export function allDepartmentsRequest(param: DepartmentsTypescript): {
  type: string
  payload: DepartmentsTypescript
} {
  return {
    type: types.ALL_DEPARTMENTS_REQUEST,
    payload: param,
  }
}
export function allDepartmentsSuccess(data: DepartmentsTypescript[]): {
  type: string
  payload: DepartmentsTypescript[]
} {
  return {
    type: types.ALL_DEPARTMENTS_SUCCESS,
    payload: data,
  }
}
export function allDepartmentsFailure(): { type: string } {
  return {
    type: types.ALL_DEPARTMENTS_FAILURE,
  }
}

export function addDepartmentRequest(param: DepartmentsTypescript): {
  type: string
  payload: DepartmentsTypescript
} {
  return {
    type: types.ADD_DEPARTMENT_REQUEST,
    payload: param,
  }
}
export function addDepartmentSuccess(data: DepartmentsTypescript): {
  type: string
  payload: DepartmentsTypescript
} {
  return {
    type: types.ADD_DEPARTMENT_SUCCESS,
    payload: data,
  }
}
export function addDepartmentFailure(): { type: string } {
  return {
    type: types.ADD_DEPARTMENT_FAILURE,
  }
}

export function updDepartmentRequest(param: DepartmentsTypescript): {
  type: string
  payload: DepartmentsTypescript
} {
  return {
    type: types.UPD_DEPARTMENT_REQUEST,
    payload: param,
  }
}
export function updDepartmentSuccess(data: DepartmentsTypescript[]): {
  type: string
  payload: DepartmentsTypescript[]
} {
  return {
    type: types.UPD_DEPARTMENT_SUCCESS,
    payload: data,
  }
}
export function updDepartmentFailure(data: DepartmentsTypescript): {
  type: string
  payload: DepartmentsTypescript
} {
  return {
    type: types.UPD_DEPARTMENT_FAILURE,
    payload: data,
  }
}
