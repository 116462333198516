import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { UsersTypescript } from '../../../typescript'
import * as UsersActions from '../../actions/users'
import * as types from '../../types'

let oneUserApi: UsersTypescript
const oneUserRequisicao = async (user: UsersTypescript) => {
  const query = `
    query {
      oneUser (_id: "${user._id}") {
        _id
        idGroup
        email
        userName
        profiles
        profilesDetail {
          usersRegistration
          economicGroupsRegistration
          branchesRegistration
          devicesRegistration
          devicesTypeRegistration
          profilesRegistration
          resellersRegistration
          commandsRegistration
        }
        associatedBranches
        token
        admin
        associatedResellers
        result
        message
        err
      }
    }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    oneUserApi = data.data.oneUser
    return true
  })
}

let allUsersApi: UsersTypescript[]
const allUsersRequisicao = async (param: UsersTypescript) => {
  const query = `
    query {
      allUsers (find: {
        ${param._id ? '_id: "' + param._id + '"' : ''}
        ${param.email ? 'email: "' + param.email + '"' : ''}
        ${param.userName ? 'userName: "' + param.userName + '"' : ''}
      }) {
        _id
        idGroup
        email
        userName
        profiles
        associatedBranches
        token
        admin
        associatedResellers
        result
        message
        err
      }
    }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allUsersApi = data.data.allUsers
    return true
  })
}

let addUsersApi: UsersTypescript
const addUserRequisicao = async (user: UsersTypescript) => {
  const query = `
  mutation {
    createUser(input:{
      admin: ${user.admin}
      associatedBranches: "${user.associatedBranches}"
      associatedResellers: "${user.associatedResellers}"
      email: "${user.email}"
      password: "${user.password}"
      profiles: "${user.profiles}"
      userName: "${user.userName}"
    })
      {
      result
      message
      err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      addUsersApi = data.data.createUser
      return true
    })
    .catch((e) => e)
}

let updUserApi
const updUserRequisicao = async (user: UsersTypescript) => {
  const query = `
  mutation {
    updateUser(update:{
      _id: "${user._id}"
      ${user.email !== undefined ? 'email: "' + user.email + '"' : ''}
      ${user.userName !== undefined ? 'userName: "' + user.userName + '"' : ''}
      ${user.profiles !== undefined ? 'profiles: "' + user.profiles + '"' : ''}
      ${user.password !== undefined ? 'password: "' + user.password + '"' : ''}
      ${
        user.associatedBranches !== undefined
          ? 'associatedBranches: "' + user.associatedBranches + '"'
          : ''
      }
      ${
        user.associatedResellers !== undefined
          ? 'associatedResellers: "' + user.associatedResellers + '"'
          : ''
      }
      ${
        user.admin !== null && user.admin !== undefined
          ? 'admin: ' + user.admin
          : ''
      }
    })
      {
        result
        message
        err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updUserApi = data.data.updateUser

    return true
  })
}

let updUserGroupApi
const updUserGroupRequisicao = async (user: UsersTypescript) => {
  const query = `
  mutation {
    updateUserGroup(update:{
      _id: "${user._id}"
      ${
        user.idGroup !== '' && user.idGroup !== undefined
          ? 'idGroup: "' + user.idGroup + '"'
          : ''
      }
    })
      {        
        result
        message
        err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updUserGroupApi = data.data.updateUserGroup

    return true
  })
}

function* oneUser(action) {
  try {
    yield call(oneUserRequisicao, action.payload)
    yield put(UsersActions.oneUserSuccess(oneUserApi))
  } catch {
    yield put(UsersActions.oneUserFailure())
  }
}

function* allUsers(action) {
  try {
    yield call(allUsersRequisicao, action.payload)
    yield put(UsersActions.allUsersSuccess(allUsersApi))
  } catch {
    yield put(UsersActions.allUsersFailure())
  }
}

function* addUser(action) {
  try {
    yield call(addUserRequisicao, action.payload)
    yield put(UsersActions.addUserSuccess(addUsersApi))
  } catch {
    yield put(UsersActions.addUserFailure())
  }
}

function* updUser(action) {
  try {
    yield call(updUserRequisicao, action.payload)
    yield put(UsersActions.updUserSuccess(updUserApi))
  } catch {
    yield put(UsersActions.updUserFailure(updUserApi))
  }
}
function* updUserGroup(action) {
  try {
    yield call(updUserGroupRequisicao, action.payload)
    yield put(UsersActions.updateUserGroupSuccess(updUserGroupApi))
  } catch {
    yield put(UsersActions.updateUserGroupFailure(updUserGroupApi))
  }
}
export default all([
  takeLatest(types.ALL_USERS_REQUEST, allUsers),
  takeLatest(types.ONE_USER_REQUEST, oneUser),
  takeLatest(types.ADD_USER_REQUEST, addUser),
  takeLatest(types.UPD_USER_REQUEST, updUser),
  takeLatest(types.UPD_USER_GROUP_REQUEST, updUserGroup),
])
