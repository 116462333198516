import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { DepartmentsTypescript } from '../../../typescript'
import * as DepartmentsActions from '../../actions/departments'
import * as types from '../../types'

let oneDepartmentApi: DepartmentsTypescript
const oneDepartmentRequisicao = async (department: DepartmentsTypescript) => {
  const query = `
    query {
      oneDepartment (_id: "${department._id}") {
        _id        
        idBranch
        idEconomicGroup
        departmentNumber
        description
        xCoordinate
        yCoordinate
        result
        message
        err
      }
    }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    oneDepartmentApi = data.data.oneDepartment
    return true
  })
}

let allDepartmentsApi: DepartmentsTypescript[]
const allDepartmentsRequisicao = async (param: DepartmentsTypescript) => {
  const query = `
    query {
      allDepartments (find: {
        ${param._id ? '_id: "' + param._id + '"' : ''}
        ${
          param.departmentNumber
            ? 'departmentNumber: "' + param.departmentNumber + '"'
            : ''
        }
        ${param.description ? 'description: "' + param.description + '"' : ''}
      }) {
        _id
        idBranch
        idEconomicGroup
        departmentNumber
        description
        xCoordinate
        yCoordinate
        result
        message
        err
      }
    }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allDepartmentsApi = data.data.allDepartments
    return true
  })
}

let addDepartmentApi: DepartmentsTypescript
const addDepartmentRequisicao = async (department: DepartmentsTypescript) => {
  const query = `
  mutation {
    createDepartments(input:{
      idBranch: "${department.idBranch}"
      description: "${department.description}"
      xCoordinate: ${department.xCoordinate}
      yCoordinate: ${department.yCoordinate}
    })
      {
      result
      message
      err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      addDepartmentApi = data.data.createDepartments
      return true
    })
    .catch((e) => e)
}

let updDepartmentApi
const updDepartmentRequisicao = async (department: DepartmentsTypescript) => {
  const query = `
  mutation {
    updateDepartments(update:{
      _id: "${department._id}"
      idBranch: "${department.idBranch}"
      description: "${department.description}"
      xCoordinate: ${department.xCoordinate}
      yCoordinate: ${department.yCoordinate}
    })
      {
        result
        message
        err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updDepartmentApi = data.data.updateDepartments

    return true
  })
}

function* oneDepartment(action) {
  try {
    yield call(oneDepartmentRequisicao, action.payload)
    yield put(DepartmentsActions.oneDepartmentSuccess(oneDepartmentApi))
  } catch {
    yield put(DepartmentsActions.oneDepartmentFailure())
  }
}

function* allDepartments(action) {
  try {
    yield call(allDepartmentsRequisicao, action.payload)
    yield put(DepartmentsActions.allDepartmentsSuccess(allDepartmentsApi))
  } catch {
    yield put(DepartmentsActions.allDepartmentsFailure())
  }
}

function* addDepartment(action) {
  try {
    yield call(addDepartmentRequisicao, action.payload)
    yield put(DepartmentsActions.addDepartmentSuccess(addDepartmentApi))
  } catch {
    yield put(DepartmentsActions.addDepartmentFailure())
  }
}

function* updDepartment(action) {
  try {
    yield call(updDepartmentRequisicao, action.payload)
    yield put(DepartmentsActions.updDepartmentSuccess(updDepartmentApi))
  } catch {
    yield put(DepartmentsActions.updDepartmentFailure(updDepartmentApi))
  }
}

export default all([
  takeLatest(types.ALL_DEPARTMENTS_REQUEST, allDepartments),
  takeLatest(types.ONE_DEPARTMENT_REQUEST, oneDepartment),
  takeLatest(types.ADD_DEPARTMENT_REQUEST, addDepartment),
  takeLatest(types.UPD_DEPARTMENT_REQUEST, updDepartment),
])
