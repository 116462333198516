import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { DevicesTypescript } from '../../../typescript'
import * as DevicesActions from '../../actions/devices'
import * as types from '../../types'

let DashBoardCardDevicesApi: DevicesTypescript[]
const DashBoardCardDevicesRequisicao = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  device: DevicesTypescript,
) => {
  const query = `
  query {
    DashBoardCardsDevices (find: {}) 
      { 
        _id
        serialNumber
        description   
        branchNumber
        timeStamp   
        economicGroupDetail {
            groupName
            groupNumber
        }      
      }
    }`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    DashBoardCardDevicesApi = data.data.DashBoardCardsDevices
    return true
  })
}

let allDevicesApi: DevicesTypescript[]
const allDevicesRequisicao = async (device: DevicesTypescript) => {
  const query = `
  query {
    allDevices(find: {      
      ${device.deviceType ? 'deviceType: "' + device.deviceType + '"' : ''}
      ${
        device.serialNumber ? 'serialNumber: "' + device.serialNumber + '"' : ''
      }
      ${device.description ? 'description: "' + device.description + '"' : ''}
      ${device._id ? '_id: "' + device._id + '"' : ''}
      ${
        device.idEconomicGroup
          ? 'idEconomicGroup: "' + device.idEconomicGroup + '"'
          : ''
      }
      ${device.branchNumber ? 'branchNumber:' + device.branchNumber : ''}
      flgActualGroup: ${device.flgActualGroup === false ? false : true}
    }) {
      _id
      deviceType
      description
      serialNumber
      idEconomicGroup
      branchNumber
      idDepartment
      idSection
      economicGroupDetail {
        groupName
        groupNumber
        tradingName
      }
      idDepartment
      departmentDetails{
          departmentNumber
          description
      }
      idSection
      sectionDetails{
          sectionNumber
          description
      }
      latitude
      longitude
      keepAliveTime
      wifiSsidPrimary
      wifiPasswordPrimary
      wifiSsidSecondary
      wifiPasswordSecondary
      apnGsm
      userNameGsm
      passwordGsm
      activeBuzzerOnAlert
      temperatureNameOne
      inputNameOne
      minimumRangeOne
      maximumRangeOne
      minimumAlertOne
      maximumAlertOne
      temperatureNameTwo
      inputNameTwo
      minimumRangeTwo
      maximumRangeTwo
      minimumAlertTwo
      maximumAlertTwo
      temperatureNameThree
      inputNameThree
      minimumRangeThree
      maximumRangeThree
      minimumAlertThree
      maximumAlertThree
      minimumAlertHumidity
      maximumAlertHumidity
      minimumRangeHumidity
      maximumRangeHumidity
      
      
      channel1
      channel2
      channel3
      channel4
      channel5
      channel6
      channel7
      channel8
      channel9
      channel10
      channel11
      tActiveOne
      tActiveTwo
      tActiveThree
      result
      message
      err
    }
  }
      `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allDevicesApi = data.data.allDevices

    return true
  })
}
let oneDeviceApi: DevicesTypescript
const oneDeviceRequisicao = async (device: DevicesTypescript) => {
  const query = `
  query {
    oneDevice( 
      ${device.serialNumber ? '_id: "' + device.serialNumber + '"' : ''}
    ) {
      _id
      deviceType
      description
      serialNumber
      idEconomicGroup
      branchNumber
      idDepartment
      idSection
      economicGroupDetail {
        groupName
        groupNumber
        tradingName
      }
      latitude
      longitude
      keepAliveTime
      wifiSsidPrimary
      wifiPasswordPrimary
      wifiSsidSecondary
      wifiPasswordSecondary
      apnGsm
      userNameGsm
      passwordGsm
      activeBuzzerOnAlert
      temperatureNameOne
      inputNameOne
      minimumRangeOne
      maximumRangeOne
      minimumAlertOne
      maximumAlertOne
      temperatureNameTwo
      inputNameTwo
      minimumRangeTwo
      maximumRangeTwo
      minimumAlertTwo
      maximumAlertTwo
      temperatureNameThree
      inputNameThree
      minimumRangeThree
      maximumRangeThree
      minimumAlertThree
      maximumAlertThree
      
      minimumAlertHumidity
      maximumAlertHumidity
      minimumRangeHumidity
      maximumRangeHumidity
      
      
      channel1
      channel2
      channel3
      channel4
      channel5
      channel6
      channel7
      channel8
      channel9
      channel10
      channel11
      tActiveOne
      tActiveTwo
      tActiveThree
      result
      message
      err
    }
  }
      `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    oneDeviceApi = data.data.oneDevice
    return true
  })
}

let allDevicesByGroupApi: DevicesTypescript[]
const allDevicesByGroupRequisicao = async (device: DevicesTypescript) => {
  const query = `
  query {
    devicesByGroupBranches(find: {      
      ${
        device.flgActualGroup
          ? 'flgActualGroup: "' + device.flgActualGroup + '"'
          : ''
      }
      ${
        device.branchNumber ? 'branchNumber: "' + device.branchNumber + '"' : ''
      }
    }) {
      serialNumber
      result
      message
      err
    }
  }
      `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allDevicesApi = data.data.allDevices
    return true
  })
}

let addDevicesApi: DevicesTypescript
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addDeviceRequisicao = async (device: DevicesTypescript) => {
  const query = `
  mutation {
    createDevices(input: {
        deviceType: "${device.deviceType}",
        description: "${device.description}",
        idDepartment: "${device.idDepartment}",
        idSection: "${device.idSection}",
        serialNumber: "${device.serialNumber}",
        idEconomicGroup: "${device.idEconomicGroup}",
        branchNumber: ${device.branchNumber},
        ${
          device.keepAliveTime !== undefined
            ? 'keepAliveTime:' + device.keepAliveTime + ','
            : ''
        }
        ${
          device.latitude !== undefined
            ? 'latitude:' + device.latitude + ','
            : ''
        }
        ${
          device.longitude !== undefined
            ? 'longitude:' + device.longitude + ','
            : ''
        }
        wifiSsidPrimary: "${device.wifiSsidPrimary}",
        wifiPasswordPrimary: "${device.wifiPasswordPrimary}",
        wifiSsidSecondary: "${device.wifiSsidSecondary}",
        wifiPasswordSecondary: "${device.wifiPasswordSecondary}",
        apnGsm: "${device.apnGsm}",
        userNameGsm: "${device.userNameGsm}",
        passwordGsm: "${device.passwordGsm}",
        activeBuzzerOnAlert: ${device.activeBuzzerOnAlert},
        temperatureNameOne: "${device.temperatureNameOne}",
        temperatureNameTwo: "${device.temperatureNameTwo}",
        temperatureNameThree: "${device.temperatureNameThree}",
        inputNameOne: "${device.inputNameOne}",
        inputNameTwo: "${device.inputNameTwo}",
        inputNameThree : "${device.inputNameThree}",
        ${
          device.minimumRangeOne !== undefined
            ? 'minimumRangeOne: ' + device.minimumRangeOne + ','
            : ''
        }
        ${
          device.maximumRangeOne !== undefined
            ? 'maximumRangeOne: ' + device.maximumRangeOne + ','
            : ''
        }
        ${
          device.minimumAlertOne !== undefined
            ? 'minimumAlertOne: ' + device.minimumAlertOne + ','
            : ''
        }
        ${
          device.maximumAlertOne !== undefined
            ? 'maximumAlertOne: ' + device.maximumAlertOne + ','
            : ''
        }
        ${
          device.minimumRangeTwo !== undefined
            ? 'minimumRangeTwo: ' + device.minimumRangeTwo + ','
            : ''
        }
        ${
          device.maximumRangeTwo !== undefined
            ? 'maximumRangeTwo: ' + device.maximumRangeTwo + ','
            : ''
        }
        ${
          device.minimumAlertTwo !== undefined
            ? 'minimumAlertTwo: ' + device.minimumAlertTwo + ','
            : ''
        }
        ${
          device.maximumAlertTwo !== undefined
            ? 'maximumAlertTwo: ' + device.maximumAlertTwo + ','
            : ''
        }
        ${
          device.minimumRangeThree !== undefined
            ? 'minimumRangeThree: ' + device.minimumRangeThree + ','
            : ''
        }
        ${
          device.maximumRangeThree !== undefined
            ? 'maximumRangeThree: ' + device.maximumRangeThree + ','
            : ''
        }
        ${
          device.minimumAlertThree !== undefined
            ? 'minimumAlertThree: ' + device.minimumAlertThree + ','
            : ''
        }
        ${
          device.maximumAlertThree !== undefined
            ? 'maximumAlertThree: ' + device.maximumAlertThree + ','
            : ''
        }
        ${
          device.minimumRangeHumidity !== undefined
            ? 'minimumRangeHumidity: ' + device.minimumRangeHumidity + ','
            : ''
        }
        ${
          device.maximumRangeHumidity !== undefined
            ? 'maximumRangeHumidity: ' + device.maximumRangeHumidity + ','
            : ''
        }
        ${
          device.minimumAlertHumidity !== undefined
            ? 'minimumAlertHumidity: ' + device.minimumAlertHumidity + ','
            : ''
        }
        ${
          device.maximumAlertHumidity !== undefined
            ? 'maximumAlertHumidity: ' + device.maximumAlertHumidity + ','
            : ''
        }
        channel1: "${device.channel1}",
        channel2: "${device.channel2}",
        channel3: "${device.channel3}",
        channel4: "${device.channel4}",
        channel5: "${device.channel5}",
        channel6: "${device.channel6}",
        channel7: "${device.channel7}",
        channel8: "${device.channel8}",
        channel9: "${device.channel9}",
        channel10: "${device.channel10}",
        channel11: "${device.channel11}",
        tActiveOne: ${device.tActiveOne},
        tActiveTwo: ${device.tActiveTwo},
        tActiveThree: ${device.tActiveThree}
      }
    ) {
      result
      message
      err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    addDevicesApi = data.data.createDevices
    return true
  })
}

let updateDeviceApi
const updateDeviceRequisicao = async (updDevice: DevicesTypescript) => {
  const query = `
  mutation {
    updateDevices( update:{
      _id: "${updDevice._id}",
      deviceType: "${updDevice.deviceType}",
        description: "${updDevice.description}",
        idDepartment: "${updDevice.idDepartment}",
        idSection: "${updDevice.idSection}",
        serialNumber: "${updDevice.serialNumber}",
        branchNumber: ${updDevice.branchNumber},
        keepAliveTime: ${updDevice.keepAliveTime},
        ${
          updDevice.latitude !== undefined
            ? 'latitude:' + updDevice.latitude + ','
            : ''
        }
        ${
          updDevice.longitude !== undefined
            ? 'longitude:' + updDevice.longitude + ','
            : ''
        }
        wifiSsidPrimary: "${updDevice.wifiSsidPrimary}",
        wifiPasswordPrimary: "${updDevice.wifiPasswordPrimary}",
        wifiSsidSecondary: "${updDevice.wifiSsidSecondary}",
        wifiPasswordSecondary: "${updDevice.wifiPasswordSecondary}",
        apnGsm: "${updDevice.apnGsm}",
        userNameGsm: "${updDevice.userNameGsm}",
        passwordGsm: "${updDevice.passwordGsm}",
        activeBuzzerOnAlert: ${updDevice.activeBuzzerOnAlert},
        temperatureNameOne: "${updDevice.temperatureNameOne}",
        temperatureNameTwo: "${updDevice.temperatureNameTwo}",
        temperatureNameThree: "${updDevice.temperatureNameThree}",
        inputNameOne: "${updDevice.inputNameOne}",
        inputNameTwo: "${updDevice.inputNameTwo}",
        inputNameThree : "${updDevice.inputNameThree}",
        ${
          updDevice.minimumRangeOne !== undefined
            ? 'minimumRangeOne: ' + updDevice.minimumRangeOne + ','
            : ''
        }
        ${
          updDevice.maximumRangeOne !== undefined
            ? 'maximumRangeOne: ' + updDevice.maximumRangeOne + ','
            : ''
        }
        ${
          updDevice.minimumAlertOne !== undefined
            ? 'minimumAlertOne: ' + updDevice.minimumAlertOne + ','
            : ''
        }
        ${
          updDevice.maximumAlertOne !== undefined
            ? 'maximumAlertOne: ' + updDevice.maximumAlertOne + ','
            : ''
        }
        ${
          updDevice.minimumRangeTwo !== undefined
            ? 'minimumRangeTwo: ' + updDevice.minimumRangeTwo + ','
            : ''
        }
        ${
          updDevice.maximumRangeTwo !== undefined
            ? 'maximumRangeTwo: ' + updDevice.maximumRangeTwo + ','
            : ''
        }
        ${
          updDevice.minimumAlertTwo !== undefined
            ? 'minimumAlertTwo: ' + updDevice.minimumAlertTwo + ','
            : ''
        }
        ${
          updDevice.maximumAlertTwo !== undefined
            ? 'maximumAlertTwo: ' + updDevice.maximumAlertTwo + ','
            : ''
        }
        ${
          updDevice.minimumRangeThree !== undefined
            ? 'minimumRangeThree: ' + updDevice.minimumRangeThree + ','
            : ''
        }
        ${
          updDevice.maximumRangeThree !== undefined
            ? 'maximumRangeThree: ' + updDevice.maximumRangeThree + ','
            : ''
        }
        ${
          updDevice.minimumAlertThree !== undefined
            ? 'minimumAlertThree: ' + updDevice.minimumAlertThree + ','
            : ''
        }
        ${
          updDevice.maximumAlertThree !== undefined
            ? 'maximumAlertThree: ' + updDevice.maximumAlertThree + ','
            : ''
        }
        ${
          updDevice.minimumRangeHumidity !== undefined
            ? 'minimumRangeHumidity: ' + updDevice.minimumRangeHumidity + ','
            : ''
        }
        ${
          updDevice.maximumRangeHumidity !== undefined
            ? 'maximumRangeHumidity: ' + updDevice.maximumRangeHumidity + ','
            : ''
        }
        ${
          updDevice.minimumAlertHumidity !== undefined
            ? 'minimumAlertHumidity: ' + updDevice.minimumAlertHumidity + ','
            : ''
        }
        ${
          updDevice.maximumAlertHumidity !== undefined
            ? 'maximumAlertHumidity: ' + updDevice.maximumAlertHumidity + ','
            : ''
        }
        channel1: "${updDevice.channel1}",
        channel2: "${updDevice.channel2}",
        channel3: "${updDevice.channel3}",
        channel4: "${updDevice.channel4}",
        channel5: "${updDevice.channel5}",
        channel6: "${updDevice.channel6}",
        channel7: "${updDevice.channel7}",
        channel8: "${updDevice.channel8}",
        channel9: "${updDevice.channel9}",
        channel10: "${updDevice.channel10}",
        channel11: "${updDevice.channel11}",
        tActiveOne: ${updDevice.tActiveOne},
        tActiveTwo: ${updDevice.tActiveTwo},
        tActiveThree: ${updDevice.tActiveThree}
    }) {
    result
    message
    err
    }
  }
`

  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      updateDeviceApi = data.data.updateDevices
      return true
    })
    .catch((e) => e)
}

let updateGroupDeviceApi
const updateGroupDeviceRequisicao = async (updDevice: DevicesTypescript) => {
  const query = `
  mutation updateGroupDevice{
    updateGroupDevices( update: {
      _id: "${updDevice._id}",
      idEconomicGroup: "${updDevice.idEconomicGroup}",
        branchNumber: ${updDevice.branchNumber},
    
    })
    {
    result
    message
    err
    }
    }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      updateGroupDeviceApi = data.data.updateGroupDevices
      return true
    })
    .catch((e) => e)
}

function* updDevice(action) {
  try {
    yield call(updateDeviceRequisicao, action.payload)
    yield put(DevicesActions.updDeviceSuccess(updateDeviceApi))
  } catch {
    yield put(DevicesActions.updDeviceFailure(updateDeviceApi))
  }
}
function* updGroupDevice(action) {
  try {
    yield call(updateGroupDeviceRequisicao, action.payload)
    yield put(DevicesActions.updGroupDeviceSuccess(updateGroupDeviceApi))
  } catch {
    yield put(DevicesActions.updGroupDeviceFailure(updateGroupDeviceApi))
  }
}

function* allDevices(action) {
  try {
    yield call(allDevicesRequisicao, action.payload)
    yield put(DevicesActions.allDevicesSuccess(allDevicesApi))
  } catch {
    yield put(DevicesActions.allDevicesFailure())
  }
}

function* oneDevice(action) {
  try {
    yield call(oneDeviceRequisicao, action.payload)
    yield put(DevicesActions.oneDeviceSuccess(oneDeviceApi))
  } catch {
    yield put(DevicesActions.oneDeviceFailure())
  }
}

function* allDevicesByGroup(action) {
  try {
    yield call(allDevicesByGroupRequisicao, action.payload)
    yield put(DevicesActions.allDevicesByGroupSuccess(allDevicesByGroupApi))
  } catch {
    yield put(DevicesActions.allDevicesByGroupFailure())
  }
}

function* DashBoardCardDevices(action) {
  try {
    yield call(DashBoardCardDevicesRequisicao, action.payload)
    yield put(DevicesActions.dashBoardDevicesSuccess(DashBoardCardDevicesApi))
  } catch {
    yield put(DevicesActions.dashBoardDevicesFailure())
  }
}
function* addDevice(action) {
  try {
    yield call(addDeviceRequisicao, action.payload)
    yield put(DevicesActions.addDeviceSuccess(addDevicesApi))
  } catch {
    yield put(DevicesActions.addDeviceFailure())
  }
}
export default all([
  takeLatest(types.ALL_DEVICES_REQUEST, allDevices),
  takeLatest(types.ONE_DEVICE_REQUEST, oneDevice),
  takeLatest(types.ALL_DEVICES_BY_GROUP_REQUEST, allDevicesByGroup),
  takeLatest(types.DASHBOARD_CARD_DEVICES_REQUEST, DashBoardCardDevices),
  takeLatest(types.ADD_DEVICE_REQUEST, addDevice),
  takeLatest(types.UPD_DEVICE_REQUEST, updDevice),
  takeLatest(types.UPD_GROUP_DEVICE_REQUEST, updGroupDevice),
])
