import { DevicesTypescript } from '../../../typescript'
import * as types from '../../types'

export interface DeviceState {
  loading: boolean
  loadingDashBoardCardDevices: boolean
  loadingByGroup: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  loadingUpdGroup: boolean
  dashBoardCardDevices: DevicesTypescript
  device: DevicesTypescript
  addDevice: DevicesTypescript
  updDevice: DevicesTypescript
  updGroupDevice: DevicesTypescript
  devices: DevicesTypescript[]
  devicesByGroup: DevicesTypescript[]
  devicesFiltered: DevicesTypescript[]
  error: string | null
}

const initialState: DeviceState = {
  loading: false,
  loadingDashBoardCardDevices: false,
  loadingByGroup: false,
  loadingOne: false,
  loadingAdd: false,
  loadingUpd: false,
  loadingUpdGroup: false,
  dashBoardCardDevices: {
    serialNumber: '',
    description: '',
    branchNumber: 0,
    timeStamp: '',
    economicGroupDetail: {
      groupName: '',
      groupNumber: 0,
    },
  },
  addDevice: {
    _id: '',
    deviceType: '', //required
    description: '', //required
    serialNumber: '', //required
    idEconomicGroup: '', //required
    branchNumber: 0, //required
    latitude: 0,
    keepAliveTime: 0,
    longitude: 0,
    wifiSsidPrimary: '',
    wifiPasswordPrimary: '',
    wifiSsidSecondary: '',
    wifiPasswordSecondary: '',
    apnGsm: '',
    userNameGsm: '',
    passwordGsm: '',
    activeBuzzerOnAlert: true,
    temperatureNameOne: '',
    temperatureNameTwo: '',
    temperatureNameThree: '',
    inputNameOne: '',
    inputNameTwo: '',
    inputNameThree: '',
    minimumRangeOne: undefined,
    maximumRangeOne: undefined,
    minimumAlertOne: undefined,
    maximumAlertOne: undefined,
    minimumRangeTwo: undefined,
    maximumRangeTwo: undefined,
    minimumAlertTwo: undefined,
    maximumAlertTwo: undefined,
    minimumRangeThree: undefined,
    maximumRangeThree: undefined,
    minimumAlertThree: undefined,
    maximumAlertThree: undefined,
    channel1: '',
    channel2: '',
    channel3: '',
    channel4: '',
    channel5: '',
    channel6: '',
    channel7: '',
    channel8: '',
    channel9: '',
    channel10: '',
    channel11: '',
    tActiveOne: true,
    tActiveTwo: true,
    tActiveThree: true,
    result: false,
    message: '',
    err: '',
  },
  updDevice: {
    _id: '',
    deviceType: '', //required
    description: '', //required
    serialNumber: '', //required
    idEconomicGroup: '', //required
    branchNumber: 0, //required
    latitude: 0,
    keepAliveTime: 0,
    longitude: 0,
    wifiSsidPrimary: '',
    wifiPasswordPrimary: '',
    wifiSsidSecondary: '',
    wifiPasswordSecondary: '',
    apnGsm: '',
    userNameGsm: '',
    passwordGsm: '',
    activeBuzzerOnAlert: true,
    temperatureNameOne: '',
    temperatureNameTwo: '',
    temperatureNameThree: '',
    inputNameOne: '',
    inputNameTwo: '',
    inputNameThree: '',
    minimumRangeOne: undefined,
    maximumRangeOne: undefined,
    minimumAlertOne: undefined,
    maximumAlertOne: undefined,
    minimumRangeTwo: undefined,
    maximumRangeTwo: undefined,
    minimumAlertTwo: undefined,
    maximumAlertTwo: undefined,
    minimumRangeThree: undefined,
    maximumRangeThree: undefined,
    minimumAlertThree: undefined,
    maximumAlertThree: undefined,
    channel1: '',
    channel2: '',
    channel3: '',
    channel4: '',
    channel5: '',
    channel6: '',
    channel7: '',
    channel8: '',
    channel9: '',
    channel10: '',
    channel11: '',
    tActiveOne: true,
    tActiveTwo: true,
    tActiveThree: true,
    result: false,
    message: '',
    err: '',
  },
  updGroupDevice: {
    _id: '',
    idEconomicGroup: '', //required
    branchNumber: 0, //required
    result: false,
    message: '',
    err: '',
  },
  device: {
    _id: '',
    deviceType: '', //required
    description: '', //required
    serialNumber: '', //required
    idEconomicGroup: '', //required
    branchNumber: 0, //required
    latitude: 0,
    keepAliveTime: 0,
    longitude: 0,
    wifiSsidPrimary: '',
    wifiPasswordPrimary: '',
    wifiSsidSecondary: '',
    wifiPasswordSecondary: '',
    apnGsm: '',
    userNameGsm: '',
    passwordGsm: '',
    activeBuzzerOnAlert: true,
    temperatureNameOne: '',
    temperatureNameTwo: '',
    temperatureNameThree: '',
    inputNameOne: '',
    inputNameTwo: '',
    inputNameThree: '',
    minimumRangeOne: undefined,
    maximumRangeOne: undefined,
    minimumAlertOne: undefined,
    maximumAlertOne: undefined,
    minimumRangeTwo: undefined,
    maximumRangeTwo: undefined,
    minimumAlertTwo: undefined,
    maximumAlertTwo: undefined,
    minimumRangeThree: undefined,
    maximumRangeThree: undefined,
    minimumAlertThree: undefined,
    maximumAlertThree: undefined,
    channel1: '',
    channel2: '',
    channel3: '',
    channel4: '',
    channel5: '',
    channel6: '',
    channel7: '',
    channel8: '',
    channel9: '',
    channel10: '',
    channel11: '',
    tActiveOne: true,
    tActiveTwo: true,
    tActiveThree: true,
    result: false,
    message: '',
    err: '',
  },
  devices: [],
  devicesByGroup: [],
  devicesFiltered: [],
  error: null,
}

const deviceReducer = (
  state = initialState,
  action: {
    type: string
    payload: DevicesTypescript[] | DevicesTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_DEVICES_REQUEST:
      return {
        ...state,
        loading: true,
        loadingByGroup: true,
        devices: action.payload,
        addDevice: { ...action.payload, result: false, message: '' },
        updDevice: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
        loadingUpdGroup: true,
      }

    case types.ALL_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        loading: false,
        loadingByGroup: false,
      }

    case types.ALL_DEVICES_FAILURE:
      return { ...state, loading: false, loadingByGroup: false }

    //one
    case types.ONE_DEVICE_REQUEST:
      return {
        ...state,
        loadingOne: true,
        device: action.payload,
      }
    case types.ONE_DEVICE_SUCCESS:
      return {
        ...state,
        device: action.payload,
        loadingOne: false,
      }
    case types.ONE_DEVICE_FAILURE:
      return state

    case types.ALL_DEVICES_BY_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        loadingByGroup: true,
        devicesByGroup: action.payload,
        addDevice: { ...action.payload, result: false, message: '' },
        updDevice: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
        loadingUpdGroup: true,
      }

    case types.ALL_DEVICES_BY_GROUP_SUCCESS:
      return {
        ...state,
        devicesByGroup: action.payload,
        loading: false,
        loadingByGroup: false,
      }

    case types.ALL_DEVICES_BY_GROUP_FAILURE:
      return { ...state, loading: false, loadingByGroup: false }

    case types.DASHBOARD_CARD_DEVICES_REQUEST:
      return {
        ...state,
        loadingDashBoardCardDevices: true,
        dashBoardCardDevices: action.payload,
        addDevice: { ...action.payload, result: false, message: '' },
        updDevice: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
        loadingUpdGroup: true,
      }

    case types.DASHBOARD_CARD_DEVICES_SUCCESS:
      return {
        ...state,
        dashBoardCardDevices: action.payload,
        loadingDashBoardCardDevices: false,
      }

    case types.DASHBOARD_CARD_DEVICES_FAILURE:
      return state

    case types.ADD_DEVICE_REQUEST:
      return { ...state, addDevice: action.payload, loadingAdd: true }

    case types.ADD_DEVICE_SUCCESS:
      return { ...state, addDevice: action.payload, loadingAdd: false }

    case types.ADD_DEVICE_FAILURE:
      return state

    case types.UPD_DEVICE_REQUEST:
      return { ...state, updDevice: action.payload, loadingUpd: true }

    case types.UPD_DEVICE_SUCCESS:
      return { ...state, updDevice: action.payload, loadingUpd: false }

    case types.UPD_DEVICE_FAILURE:
      return { ...state, updDevice: action.payload }

    case types.UPD_GROUP_DEVICE_REQUEST:
      return { ...state, updGroupDevice: action.payload, loadingUpdGroup: true }

    case types.UPD_GROUP_DEVICE_SUCCESS:
      return {
        ...state,
        updGroupDevice: action.payload,
        loadingUpdGroup: false,
      }

    case types.UPD_GROUP_DEVICE_FAILURE:
      return { ...state, updGroupDevice: action.payload }

    case types.DEL_DEVICE_REQUEST:
      return state

    case types.DEL_DEVICE_SUCCESS:
      return { ...state }

    case types.DEL_DEVICE_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default deviceReducer
