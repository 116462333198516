import { ResellersTypescript } from '../../../typescript'
import * as types from '../../types'

export function allResellersRequest(reseller: ResellersTypescript): {
  type: string
  payload: ResellersTypescript
} {
  return {
    type: types.ALL_RESELLERS_REQUEST,
    payload: reseller,
  }
}
export function allResellersSuccess(data: ResellersTypescript[]): {
  type: string
  payload: ResellersTypescript[]
} {
  return {
    type: types.ALL_RESELLERS_SUCCESS,
    payload: data,
  }
}
export function allResellersFailure(): { type: string } {
  return {
    type: types.ALL_RESELLERS_FAILURE,
  }
}

export function allowedResellersRequest(): {
  type: string
} {
  return {
    type: types.ALLOWED_RESELLERS_REQUEST,
  }
}
export function allowedResellersSuccess(data: ResellersTypescript[]): {
  type: string
  payload: ResellersTypescript[]
} {
  return {
    type: types.ALLOWED_RESELLERS_SUCCESS,
    payload: data,
  }
}
export function allowedResellersFailure(): { type: string } {
  return {
    type: types.ALLOWED_RESELLERS_FAILURE,
  }
}

export function addResellerRequest(reseller: ResellersTypescript): {
  type: string
  payload: ResellersTypescript
} {
  return {
    type: types.ADD_RESELLER_REQUEST,
    payload: reseller,
  }
}
export function addResellerSuccess(data: ResellersTypescript): {
  type: string
  payload: ResellersTypescript
} {
  return {
    type: types.ADD_RESELLER_SUCCESS,
    payload: data,
  }
}
export function addResellerFailure(): { type: string } {
  return {
    type: types.ADD_RESELLER_FAILURE,
  }
}

export function updResellerRequest(reseller: ResellersTypescript): {
  type: string
  payload: ResellersTypescript
} {
  return {
    type: types.UPD_RESELLER_REQUEST,
    payload: reseller,
  }
}
export function updResellerSuccess(data: ResellersTypescript[]): {
  type: string
  payload: ResellersTypescript[]
} {
  return {
    type: types.UPD_RESELLER_SUCCESS,
    payload: data,
  }
}
export function updResellerFailure(data: ResellersTypescript): {
  type: string
  payload: ResellersTypescript
} {
  return {
    type: types.UPD_RESELLER_FAILURE,
    payload: data,
  }
}
